import React, { FC, ReactNode, useEffect, useState } from "react";
import { H5Span, Form, FormGroup, FormInput } from "../../components";
import { Cohort } from "../../models";
import { Icon } from "react-icons-kit";
import { plus } from "react-icons-kit/feather/plus";
import { edit2 } from "react-icons-kit/feather/edit2";
import { check } from "react-icons-kit/feather/check";
import { useAllCohorts, useSelectedSimulation } from "../../hooks";
import { PrimaryButtonOutline } from "../../components/admin-components";
import { Link as InertiaLink } from "@inertiajs/react";
import { route } from "ziggy-js";
import { SapienInertia } from "@/inertia-utils/hooks";
import DeleteCohortOverlay from "./DeleteCohortOverlay";
import { Trash } from "lucide-react";

const CohortRowLayout: FC<{
    cohort: Cohort;
    children?: ReactNode;
}> = ({ cohort, children }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <div
            key={cohort.id}
            style={{ minHeight: "100%" }}
            className="xs:grid-cols-1 relative grid gap-2 rounded-md border bg-white px-4 py-2
                hover:shadow-md sm:grid-cols-2"
        >
            <div className="flex flex-col justify-between space-y-1 pr-4">
                {children}
                <div className="flex flex-col whitespace-nowrap text-xs tracking-tight text-[#6B7280]">
                    <span>
                        {`Created ${cohort.created_at?.substring(0, 10)}`}
                    </span>
                </div>
            </div>
            <div className="grid grid-cols-4">
                <div className="flex flex-col justify-center">
                    <div className="flex text-sm text-[#6B7280]">{`Teams`}</div>
                    <div className="text-sm font-semibold">{`${cohort.teams_count}`}</div>
                </div>
                <div className="flex flex-col justify-center">
                    <div className="flex text-sm text-[#6B7280]">{`Participants`}</div>
                    <div className="text-sm font-semibold">{`${cohort.team_user_rounds_count}`}</div>
                </div>
                <div className="flex flex-col justify-center">
                    <div className="flex text-sm text-[#6B7280]">{`Selections`}</div>
                    <div className="text-sm font-semibold">{`${cohort.selections_count}`}</div>
                </div>
                <div className="flex flex-col justify-center">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsDeleting(true);
                        }}
                    >
                        <span className="sr-only">Delete</span>
                        <Trash className="h-5 w-5" />
                    </button>
                </div>
            </div>
            {!!isDeleting && (
                <DeleteCohortOverlay
                    cohort={cohort}
                    cancelDelete={() => setIsDeleting(false)}
                />
            )}
        </div>
    );
};

export const CohortList = () => {
    const { selectedSimulation } = useSelectedSimulation();
    const { cohorts } = useAllCohorts();

    const [editedCohort, setEditedCohort] = useState<Cohort>();

    useEffect(() => {
        if (!!editedCohort) {
            document.getElementById(`cohort-${editedCohort.id}`).focus();
        }
    }, [editedCohort?.id]);

    return (
        <div className="p-6">
            <div className="mb-4 flex min-h-[46px] flex-row items-center justify-between">
                <H5Span>Cohorts</H5Span>
                <PrimaryButtonOutline
                    text={"Create Cohort"}
                    icon={plus}
                    handler={() =>
                        SapienInertia.post(
                            "admin.facilitate.save-cohort",
                            {
                                title: `Cohort ${(cohorts?.length || 0) + 1}`,
                                simulation_id: selectedSimulation?.id,
                            },
                            {},
                            {
                                preserveScroll: true,
                            },
                        )
                    }
                />
            </div>
            <div className="grid w-full gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
                {cohorts
                    .filter((cohort) => cohort.title !== "Sandbox Cohort")
                    .reverse()
                    .map((cohort) =>
                        editedCohort?.id !== cohort.id ? (
                            <InertiaLink
                                key={cohort.id}
                                href={route("admin.projects.facilitate.show", {
                                    simulationSlug: selectedSimulation?.slug,
                                    cohortId: cohort.id,
                                })}
                            >
                                <CohortRowLayout cohort={cohort}>
                                    <div className="flex flex-row items-center">
                                        <h2 className="text-md font-bold">
                                            {cohort.title}
                                        </h2>
                                        <div className="ml-1">
                                            <button
                                                type="button"
                                                className="m-1 inline-flex items-center rounded-full p-2 text-center text-sm font-medium
                                                    text-blue-700 hover:bg-blue-800 hover:bg-opacity-5 focus:outline-none
                                                    focus:ring-0 focus:ring-blue-300"
                                                disabled={false}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setEditedCohort(cohort);
                                                }}
                                            >
                                                <Icon
                                                    icon={edit2}
                                                    size={14}
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </CohortRowLayout>
                            </InertiaLink>
                        ) : (
                            <CohortRowLayout key={cohort.id} cohort={cohort}>
                                <div className="flex flex-row items-center">
                                    <div className="w-full pr-1">
                                        <Form>
                                            <FormGroup style={{ margin: 0 }}>
                                                <FormInput
                                                    type="text"
                                                    placeholder="Title"
                                                    value={editedCohort.title}
                                                    id={`cohort-${cohort.id}`}
                                                    onChange={(e) => {
                                                        setEditedCohort({
                                                            ...editedCohort,
                                                            title: e.target
                                                                .value,
                                                        } as Cohort);
                                                    }}
                                                />
                                            </FormGroup>
                                        </Form>
                                    </div>
                                    <div className="ml-1">
                                        <button
                                            type="button"
                                            className="m-1 inline-flex items-center rounded-full p-2 text-center text-sm font-medium
                                                text-blue-700 hover:bg-blue-800 hover:bg-opacity-5 focus:outline-none
                                                focus:ring-0 focus:ring-blue-300"
                                            disabled={false}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                SapienInertia.post(
                                                    "admin.facilitate.save-cohort",
                                                    {
                                                        ...editedCohort,
                                                    },
                                                    {},
                                                    {
                                                        preserveScroll: true,
                                                    },
                                                );
                                                setEditedCohort(undefined);
                                            }}
                                        >
                                            <Icon
                                                icon={check}
                                                size={14}
                                                style={{
                                                    display: "flex",
                                                }}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </CohortRowLayout>
                        ),
                    )}
            </div>
        </div>
    );
};
