import React from "react";
import Authenticated from "../../Layouts/Authenticated";
import { Head, Link as InertiaLink } from "@inertiajs/react";
import { SapienParticipantPageProps } from "../../inertia-utils/types";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { sapienRoute } from "@/inertia-utils/hooks";
import { GraduationCap } from "lucide-react";
import DashboardCard from "./DashboardCard";
import { useInitAuthFromInertiaProps } from "@/hooks";
import InteractiveModelBlockDashboardCardContents from "./InteractiveModelBlockDashboardCardContents";
import NavLink from "@/components/NavLink";

type Props = App.Data.ParticipantDashboardData & SapienParticipantPageProps;

export default function Dashboard({
    auth,
    teams,
    tenant,
    errors,
    impersonating_user,
    currentTeamUserRound,
    simulationTeamUserRounds,
    courseTeamUserRounds,
    projectionTools,
}: Props) {
    const {} = useInitAuthFromInertiaProps(auth, null, null, tenant);

    return (
        <Authenticated
            auth={auth}
            errors={errors}
            impersonatingUser={impersonating_user}
            links={
                <NavLink active={false} href={sapienRoute("resources")}>
                    Resources
                </NavLink>
            }
        >
            <>
                <Head title="Dashboard" />
                <div className="mx-auto my-0 w-full max-w-7xl">
                    <div className="flex flex-col space-y-16 p-8">
                        {!!courseTeamUserRounds?.length && (
                            <div>
                                <h2
                                    className="flex items-center space-x-4 pb-6 text-xl font-semibold leading-tight
                                        text-gray-800"
                                >
                                    <GraduationCap className="h-12 w-12" />
                                    <span>Your Courses</span>
                                </h2>
                                <ul
                                    role="list"
                                    className="grid grid-cols-1 gap-6 p-0 lg:grid-cols-3"
                                >
                                    {courseTeamUserRounds
                                        .filter(
                                            (teamUserRound) =>
                                                !!teamUserRound?.round
                                                    .simulation,
                                        )
                                        .map((teamUserRound) => {
                                            const team = teamUserRound.team;
                                            const round = teamUserRound.round;
                                            return (
                                                <DashboardCard
                                                    imageUrl={
                                                        teamUserRound.round
                                                            .simulation?.theme
                                                            ?.image_url ||
                                                        tenant?.logo_url
                                                    }
                                                    key={team.id}
                                                >
                                                    <>
                                                        <div
                                                            className="pointer-events-none absolute inset-0 bg-gradient-to-b from-slate-900/50
                                                                to-slate-900/60"
                                                        ></div>
                                                        <div
                                                            className={`absolute right-6 top-6 inline-flex items-center rounded-md border border-white
                                                                px-2 py-0.5 text-xs font-medium text-white`}
                                                        >
                                                            <span>{`${
                                                                round
                                                                    ?.simulation
                                                                    ?.rounds_count
                                                            } ${
                                                                round.simulation
                                                                    ?.rounds_count ===
                                                                1
                                                                    ? "lesson"
                                                                    : "lessons"
                                                            }`}</span>
                                                        </div>
                                                        <div className="relative z-[999] flex w-full flex-col items-start space-y-6">
                                                            <h3 className="flex items-center font-bold text-gray-50 ">
                                                                {
                                                                    round
                                                                        ?.simulation
                                                                        ?.title
                                                                }
                                                            </h3>
                                                            <h5 className="!mt-1 text-sm text-gray-100">
                                                                {
                                                                    round
                                                                        ?.cohort
                                                                        ?.title
                                                                }
                                                            </h5>
                                                            {!!round?.simulation && (
                                                                <InertiaLink
                                                                    href={sapienRoute(
                                                                        "team-user-round.update-and-navigate",
                                                                        {
                                                                            teamUserRound:
                                                                                teamUserRound.id,
                                                                        },
                                                                    )}
                                                                    className="inline-flex w-full items-center justify-center space-x-2 rounded-md
                                                                        bg-indigo-600 px-3.5 py-2 text-center text-sm text-white hover:bg-indigo-700
                                                                        focus:outline-none focus:ring-0 disabled:bg-indigo-700"
                                                                >
                                                                    <span>
                                                                        Resume
                                                                    </span>
                                                                    <span>
                                                                        <ArrowRightIcon className="ml-1.5 h-4 w-4" />
                                                                    </span>
                                                                </InertiaLink>
                                                            )}
                                                            {!!teamUserRound && (
                                                                <div className="mt-3 w-full border-t border-gray-200 pt-3 text-sm text-gray-200">
                                                                    {`Current Lesson: ${teamUserRound.round.title}`}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                </DashboardCard>
                                            );
                                        })}
                                </ul>
                            </div>
                        )}
                        {!!simulationTeamUserRounds?.length && (
                            <div>
                                <h2
                                    className="flex items-center space-x-4 pb-6 text-xl font-semibold leading-tight
                                        text-gray-800"
                                >
                                    <svg
                                        className="h-12 w-12"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1200pt"
                                        height="1200pt"
                                        version="1.1"
                                        viewBox="0 0 1200 1200"
                                    >
                                        <path d="m1068 477.49h-36.523c-26.887-81.113-103.35-139.88-193.39-139.88h-476.1c-90.039 0-166.5 58.762-193.39 139.88h-36.523c-50.062 0-90.789 40.727-90.789 90.75v97.949c0 50.023 40.727 90.75 90.789 90.75h44.176c13.461 28.352 33.863 53.363 60.188 71.926 31.426 22.164 67.539 33.562 104.21 33.562 20.438 0 41.102-3.5234 61.164-10.727 137.74-49.164 258.64-49.164 396.38 0 56.102 20.023 116.4 11.699 165.41-22.836 26.363-18.562 46.762-43.574 60.188-71.926h44.176c50.062 0 90.789-40.727 90.789-90.75v-97.949c0.03125-50.027-40.695-90.75-90.758-90.75zm-935.92 212.02c-12.824 0-23.289-10.426-23.289-23.289v-97.949c0-12.824 10.461-23.289 23.289-23.289h26.102v133.12c0 3.8242 0.44922 7.5742 0.67578 11.363l-26.777 0.003906zm792.67 84.227c-30.824 21.711-68.664 26.961-103.84 14.438-152.51-54.414-289.2-54.414-441.71 0-35.211 12.562-73.051 7.3125-103.84-14.438-31.574-22.238-49.648-57.074-49.648-95.625v-136.73c0-75.148 61.125-136.27 136.31-136.27h476.1c75.148 0 136.31 61.125 136.31 136.27v136.73c-0.042969 38.551-18.156 73.387-49.695 95.625zm166.54-107.51c0 12.824-10.461 23.289-23.289 23.289h-26.812c0.22656-3.7891 0.67578-7.5391 0.67578-11.363v-133.12h26.102c12.824 0 23.289 10.426 23.289 23.289l-0.003906 97.91z" />
                                        <path d="m658.61 465.79h-117.19c-18.637 0-33.75 15.113-33.75 33.75 0 18.637 15.113 33.75 33.75 33.75h117.19c18.637 0 33.75-15.113 33.75-33.75 0-18.641-15.113-33.75-33.75-33.75z" />
                                    </svg>

                                    <span>Your Simulations</span>
                                </h2>

                                <ul
                                    role="list"
                                    className="grid grid-cols-1 gap-6 p-0 lg:grid-cols-3"
                                >
                                    {simulationTeamUserRounds
                                        .filter(
                                            (teamUserRound) =>
                                                !!teamUserRound?.round
                                                    .simulation,
                                        )
                                        .map((teamUserRound) => {
                                            const team = teamUserRound.team;
                                            const round = teamUserRound.round;
                                            return (
                                                <DashboardCard
                                                    imageUrl={
                                                        round?.simulation?.theme
                                                            ?.image_url ||
                                                        tenant?.logo_url
                                                    }
                                                    key={team.id}
                                                >
                                                    <>
                                                        <div
                                                            className="pointer-events-none absolute inset-0 bg-gradient-to-b from-slate-900/50
                                                                to-slate-900/60"
                                                        ></div>
                                                        <div
                                                            className={`absolute right-6 top-6 inline-flex items-center rounded-md border border-white
                                                                px-2 py-0.5 text-xs font-medium text-white`}
                                                        >
                                                            <span>{`${
                                                                round
                                                                    ?.simulation
                                                                    ?.rounds_count
                                                            } ${
                                                                round.simulation
                                                                    ?.rounds_count ===
                                                                1
                                                                    ? "round"
                                                                    : "rounds"
                                                            }`}</span>
                                                        </div>
                                                        <div className="relative z-[999] flex w-full flex-col items-start space-y-6">
                                                            <h3 className="flex items-center font-bold text-gray-50 ">
                                                                {
                                                                    round
                                                                        ?.simulation
                                                                        ?.title
                                                                }
                                                            </h3>
                                                            <h5 className="!mt-1 text-sm text-gray-100">
                                                                {
                                                                    team?.cohort
                                                                        ?.title
                                                                }
                                                            </h5>
                                                            <h5 className="!mt-1 text-sm text-gray-100">
                                                                {team?.name}
                                                            </h5>
                                                            {!!round?.simulation && (
                                                                <InertiaLink
                                                                    href={sapienRoute(
                                                                        "team-user-round.update-and-navigate",
                                                                        {
                                                                            teamUserRound:
                                                                                teamUserRound.id,
                                                                        },
                                                                    )}
                                                                    className="inline-flex w-full items-center justify-center space-x-2 rounded-md
                                                                        bg-indigo-600 px-3.5 py-2 text-center text-sm text-white hover:bg-indigo-700
                                                                        focus:outline-none focus:ring-0 disabled:bg-indigo-700"
                                                                >
                                                                    <span>
                                                                        Resume
                                                                    </span>
                                                                    <span>
                                                                        <ArrowRightIcon className="ml-1.5 h-4 w-4" />
                                                                    </span>
                                                                </InertiaLink>
                                                            )}
                                                            {!!teamUserRound && (
                                                                <div className="mt-3 w-full border-t border-gray-200 pt-3 text-sm text-gray-200">
                                                                    {`Current Round: ${teamUserRound.round.title}`}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                </DashboardCard>
                                            );
                                        })}
                                </ul>
                            </div>
                        )}
                        {!!projectionTools?.length && (
                            <div>
                                <h2
                                    className="flex items-center space-x-4 pb-6 text-xl font-semibold leading-tight
                                        text-gray-800"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-12 w-12"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M14.828 14.828 21 21" />
                                        <path d="M21 16v5h-5" />
                                        <path d="m21 3-9 9-4-4-6 6" />
                                        <path d="M21 8V3h-5" />
                                    </svg>

                                    <span>Projection Tools</span>
                                </h2>

                                {
                                    <ul
                                        role="list"
                                        className="grid grid-cols-1 gap-6 p-0 lg:grid-cols-3"
                                    >
                                        {projectionTools.map((modelBlock) => {
                                            const simulation =
                                                modelBlock.simulation;
                                            return (
                                                <DashboardCard
                                                    imageUrl={
                                                        simulation?.theme
                                                            ?.image_url ||
                                                        tenant?.logo_url
                                                    }
                                                    key={modelBlock.id}
                                                >
                                                    <InteractiveModelBlockDashboardCardContents
                                                        modelBlock={modelBlock}
                                                    />
                                                </DashboardCard>
                                            );
                                        })}
                                    </ul>
                                }
                            </div>
                        )}
                    </div>
                </div>
            </>
        </Authenticated>
    );
}
