import React, { useEffect, useMemo, useState } from "react";
import { SapienModelDesignPageProps } from "@/inertia-utils/types";
import { TopNavLinkDiv } from "@/components";
import { Link as InertiaLink } from "@inertiajs/react";
import { route } from "ziggy-js";
import LogoImage from "@/components/LogoImage";
import {
    DesignModelBlockDisplay,
    DesignVariableTableDisplay,
    ModelBlockExpandCollapseMenu,
} from "@/Layouts/model-design";
import { groupBy } from "lodash";
import { getLineageIds, flatten, createNestedTree } from "@/util";
import { ModelBlock } from "@/models";
import { useSelectedSimulation } from "@/hooks";

const toolbarHeight = 42;
const colors = {
    bgTop: "#15171a",
    bgMenu: "#2d2f31",
    bgPanel: "#1f1f1f",
    menuColor: "white",
};

export default function ModelDesignContainer(
    props: SapienModelDesignPageProps,
) {
    const {
        simulation,
        timeHorizons,
        // rounds,
        modelBlocks,
        modelVariables,
        // variableRelationships,
    } = props;

    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
    }, [simulation]);

    const designerVariablesGroupedByModelBlockId = useMemo(() => {
        return groupBy(
            modelVariables?.filter(
                (modelVariable) => modelVariable.expose_to_designer,
            ) || [],
            "model_block_id",
        );
    }, [modelVariables]);

    const flattenedModelBlocks = useMemo<ModelBlock[]>(() => {
        return flatten(modelBlocks || [], "modelBlocks", "id");
    }, [modelBlocks]);

    const modelBlockLineageIds = useMemo<{
        [modelBlockId: string]: boolean;
    }>(() => {
        let filtered = {};
        const flatObject = flattenedModelBlocks.reduce(
            (map, item) => ({
                ...map,
                [item.id]: item,
            }),
            {},
        );
        Object.keys(designerVariablesGroupedByModelBlockId).forEach(
            (blockId) => {
                let lineageIds =
                    getLineageIds(
                        blockId,
                        flatObject,
                        "parent_model_block_id",
                    ) || [];
                filtered = {
                    ...filtered,
                    [blockId]: true,
                    ...lineageIds.reduce(
                        (map, id) => ({ ...map, [id]: true }),
                        {},
                    ),
                };
            },
        );
        return filtered;
    }, [flattenedModelBlocks, designerVariablesGroupedByModelBlockId]);

    const filteredNestedModelBlocks = useMemo<ModelBlock[]>(() => {
        return createNestedTree<ModelBlock>(
            "parent_model_block_id",
            "modelBlocks",
            flattenedModelBlocks
                .filter((modelBlock) => !!modelBlockLineageIds[modelBlock.id])
                .map(
                    (modelBlock) =>
                        ({ ...modelBlock, modelBlocks: [] }) as ModelBlock,
                ),
        );
    }, [flattenedModelBlocks, modelBlockLineageIds]);

    const [modelBlockAccordionMap, setModelBlockAccordionMap] = useState<{
        [modelBlockId: string]: boolean;
    }>({
        ...modelBlockLineageIds,
        ...filteredNestedModelBlocks.reduce(
            (map, modelBlock) => ({
                ...map,
                ...modelBlock.modelBlocks?.reduce(
                    (map, modelBlock) => ({
                        ...map,
                        [modelBlock.id]: false,
                    }),
                    {},
                ),
            }),
            {},
        ),
    });

    return (
        simulation !== undefined && (
            <>
                <div
                    className="model-design-container"
                    style={{
                        display: "flex",
                        height: "100vh",
                        flexDirection: "column",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <div
                        className="model-design-top"
                        style={{
                            display: "flex",
                            height: `${toolbarHeight}px`,
                            flexDirection: "row",
                        }}
                    >
                        <div
                            className="model-design-top-logo"
                            style={{
                                width: `${toolbarHeight}px`,
                                minWidth: `${toolbarHeight}px`,
                                height: `${toolbarHeight}px`,
                                background: colors.bgTop,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <TopNavLinkDiv style={{ padding: 0 }}>
                                <InertiaLink
                                    href={
                                        !!simulation &&
                                        route("admin.projects.show", {
                                            sim: simulation.slug,
                                        })
                                    }
                                >
                                    <LogoImage size={"22"} opacity={"0.9"} />
                                </InertiaLink>
                            </TopNavLinkDiv>
                        </div>
                        <div
                            className="model-design-top-center"
                            style={{
                                width: `calc(100% - ${toolbarHeight}px)`,
                                minWidth: `calc(100% - ${toolbarHeight}px)`,
                                height: `${toolbarHeight}px`,
                                background: colors.bgTop,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                color: colors.menuColor,
                                transition: "all .25s ease",
                            }}
                        >
                            <div className="flex flex-row flex-wrap items-center">
                                <div
                                    className="px-3 text-sm font-extrabold"
                                    style={{
                                        color: colors.menuColor,
                                    }}
                                >
                                    Simulation Structure
                                </div>
                                <div className="px-3 text-sm font-normal">
                                    {`${simulation.title}`}
                                </div>
                            </div>
                            <div
                                className="model-design-top-right-links"
                                style={{
                                    height: "100%",
                                    background: colors.bgTop,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: colors.menuColor,
                                    transition: "all .25s ease",
                                }}
                            ></div>
                        </div>
                    </div>

                    <div
                        className="model-design-bottom"
                        style={{
                            display: "flex",
                            height: `calc(100vh  - ${toolbarHeight}px)`,
                            flexDirection: "row",
                        }}
                    >
                        <div
                            className="model-design-center-panel w-full"
                            style={{
                                transition: "all .25s ease",
                                height: "100%",
                                overflow: "auto",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    maxWidth: "1290px",
                                    margin: "0 auto",
                                    minWidth: "300px",
                                }}
                            >
                                <div className="p-6">
                                    {!!modelVariables?.filter(
                                        (modelVariable) =>
                                            modelVariable.is_key_metric,
                                    ).length && (
                                        <div className="mb-6 rounded-md bg-indigo-50 bg-opacity-80 p-4">
                                            <div className="mb-3 text-xl">
                                                Key Metrics
                                            </div>
                                            <DesignVariableTableDisplay
                                                modelVariables={modelVariables.filter(
                                                    (modelVariable) =>
                                                        modelVariable.is_key_metric,
                                                )}
                                                timeHorizons={timeHorizons}
                                            />
                                        </div>
                                    )}
                                    {!!modelVariables?.filter(
                                        (modelVariable) =>
                                            modelVariable.expose_to_designer,
                                    ).length && (
                                        <div className="mb-6 rounded-md bg-indigo-50 bg-opacity-80 p-4">
                                            <div className="mb-3 flex items-baseline justify-between">
                                                <div className="text-xl">
                                                    Designer Settings
                                                </div>
                                                <ModelBlockExpandCollapseMenu
                                                    filteredNestedModelBlocks={
                                                        filteredNestedModelBlocks
                                                    }
                                                    modelBlockAccordionMap={
                                                        modelBlockAccordionMap
                                                    }
                                                    setModelBlockAccordionMap={
                                                        setModelBlockAccordionMap
                                                    }
                                                />
                                            </div>
                                            {!!filteredNestedModelBlocks?.length &&
                                                filteredNestedModelBlocks.map(
                                                    (modelBlock) => (
                                                        <DesignModelBlockDisplay
                                                            key={modelBlock.id}
                                                            modelBlock={
                                                                modelBlock
                                                            }
                                                            ancestry={[]}
                                                            modelVariables={
                                                                designerVariablesGroupedByModelBlockId
                                                            }
                                                            timeHorizons={
                                                                timeHorizons
                                                            }
                                                            modelBlockAccordionMap={
                                                                modelBlockAccordionMap
                                                            }
                                                            setModelBlockAccordionMap={
                                                                setModelBlockAccordionMap
                                                            }
                                                        />
                                                    ),
                                                )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    );
}
