import React from "react";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import { Course } from "../types";
import CoursePageWrapper from "./CoursePageWrapper";
import { CourseCard } from "./CourseCard";
import { SapienInertia } from "@/inertia-utils/hooks";
import { useInitAuthFromInertiaProps } from "@/hooks";
import { Simulation } from "@/models";

type Props = {
    courses: Course[];
} & SapienAdminPageProps;
export default function Index({ auth, courses, errors, tenant }: Props) {

    useInitAuthFromInertiaProps(auth,courses as Simulation[], null, tenant)

    function createCourse() {
        SapienInertia.post(
            "e-learning.courses.store",
            {
                estimated_time: 3600,
            },
            {},
        );
    }

    return (
        <CoursePageWrapper
            auth={auth}
            errors={errors}
            showDefaultHeader={false}
        >
            <header className="e-learning bg-white shadow-sm">
                <div className="mx-auto flex max-w-7xl justify-between px-4 py-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg font-semibold leading-6 text-gray-900">
                        Courses
                    </h1>
                    <div>
                        <button
                            onClick={createCourse}
                            className="inline-flex items-center rounded-md border border-transparent bg-sapien-blue
                                px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sapien-blue
                                focus:outline-none focus:ring-2 focus:ring-sapien-blue focus:ring-offset-2"
                        >
                            Create Course
                        </button>
                    </div>
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl gap-4 py-6 sm:px-6 lg:px-8">
                    {courses.map((course) => (
                        <CourseCard key={course.id} course={course} />
                    ))}{" "}
                </div>
            </main>
        </CoursePageWrapper>
    );
}
