import React, { useMemo } from "react";
import Icon from "react-icons-kit";
import { bookOpen } from "react-icons-kit/feather/bookOpen";
import { layers } from "react-icons-kit/feather/layers";
import { sliders } from "react-icons-kit/feather/sliders";
import { grid } from "react-icons-kit/feather/grid";
import { ic_lightbulb_outline } from "react-icons-kit/md/ic_lightbulb_outline";
import { ic_palette_outline } from "react-icons-kit/md/ic_palette_outline";
import { InteractivePageSection } from "./types";
import { ScrollableWrapper } from "./shared";

const menuItemObject: Record<InteractivePageSection, any> = {
    Dashboard: grid,
    Settings: sliders,
    Data: layers,
    Insights: ic_lightbulb_outline,
    Design: ic_palette_outline,
    Guides: bookOpen,
};

const adminSections: InteractivePageSection[] = [
    "Dashboard",
    "Settings",
    "Data",
    "Insights",
    "Design",
    "Guides",
];
const participantSections: InteractivePageSection[] = [
    "Dashboard",
    "Settings",
    "Data",
    "Insights",
];

function InteractiveModuleMenuComponent({
    pageSection,
    setPageSection,
    isNotParticipant,
    archetypeId,
}: {
    pageSection: InteractivePageSection;
    setPageSection: (pageSection: InteractivePageSection) => void;
    isNotParticipant: boolean;
    archetypeId: string;
}) {
    const sections = useMemo(() => {
        return isNotParticipant ? adminSections : participantSections;
    }, [isNotParticipant]);

    return (
        <div
            className={`interactive-left-menu h-full w-11 min-w-11 overflow-hidden border-r bg-slate-200
            text-slate-700 transition-all sm:w-20 sm:min-w-20 dark:bg-slate-800
            dark:text-slate-300 ${
                !!archetypeId
                    ? "border-blue-700 dark:border-blue-700"
                    : "border-slate-300 dark:border-slate-700"
            }`}
        >
            <ScrollableWrapper>
                <div className={"h-full w-full"}>
                    {sections?.map((key) => (
                        <div
                            key={key}
                            className={`flex w-full cursor-pointer flex-col items-center justify-center py-2
                            transition-all hover:bg-slate-300 hover:bg-opacity-50 dark:hover:bg-slate-700
                            dark:hover:bg-opacity-50 ${
                                pageSection == key
                                    ? "bg-slate-300 dark:bg-slate-700"
                                    : ""
                            }`}
                            onClick={() => {
                                setPageSection(key as InteractivePageSection);
                            }}
                        >
                            <Icon
                                icon={menuItemObject[key]}
                                size={24}
                                className="flex h-8 items-center justify-center text-slate-500 transition-all
                                    dark:text-slate-400"
                                style={{ display: "flex" }}
                            />
                            <span className="hidden text-xs font-light sm:flex">
                                {key}
                            </span>
                        </div>
                    ))}
                </div>
            </ScrollableWrapper>
        </div>
    );
}

export const InteractiveModuleMenu = React.memo(InteractiveModuleMenuComponent);
