import React from "react";
import { ChartBlock } from "../types";
import { Field, Label, Switch } from "@headlessui/react";
import { useMutation } from "@tanstack/react-query";
import { sapienAxios } from "@/inertia-utils/hooks";
import { useSaveChartBlock } from "./useMutateCharts";

type Props = {
    chartBlock: ChartBlock;
    updateBlock: (chartBlock: ChartBlock) => void;
};

export default function PublishSwitch({ chartBlock, updateBlock }: Props) {
    const { mutateAsync, isPending: isSaving } = useSaveChartBlock();
    const { isPending, mutate } = useMutation({
        mutationFn: async (chartBlock: ChartBlock) => {
            if (!!chartBlock.published_at) {
                // Unpublish
                const { data } = await sapienAxios.put<
                    { chart: ChartBlock },
                    "interactive.unpublish"
                >(
                    "interactive.unpublish",
                    { id: chartBlock.id },
                    { interactiveModuleChart: chartBlock.id },
                );
                return data?.chart;
            } else {
                const { data } = await sapienAxios.put<
                    { chart: ChartBlock },
                    "interactive.publish"
                >(
                    "interactive.publish",
                    { id: chartBlock.id },
                    { interactiveModuleChart: chartBlock.id },
                );
                return data?.chart;
            }
        },
        onSuccess: (chart) => {
            updateBlock(chart);
        },
    });

    return (
        <Field
            className="flex items-center gap-1 border border-slate-100 bg-white bg-opacity-50 p-1
                dark:border-slate-700 dark:bg-slate-800 dark:bg-opacity-50"
        >
            <Label className="text-sm">Publish</Label>
            <Switch
                disabled={isPending || isSaving}
                defaultChecked={!!chartBlock.published_at}
                onChange={async () => {
                    const savedBlock = await mutateAsync(chartBlock);
                    mutate(savedBlock);
                }}
                className="transition: ; group inline-flex h-5 w-8 items-center rounded-full bg-gray-200
                    data-[checked]:bg-blue-600 disabled:animate-pulse disabled:cursor-not-allowed
                    dark:bg-slate-600"
            >
                <span
                    className="size-4 rounded-full bg-white transition translate-x-[.15rem]
                        group-data-[checked]:translate-x-[.85rem]"
                />
            </Switch>
        </Field>
    );
}
