import React, { useEffect, useState } from "react";
import {
    ModelVariable,
    ModelVariableAggregateOperation,
    ModelVariableDataType,
    ModelVariableResampleFunction,
    ModelVariableScope,
    ModelVariableType,
    ModelVariableUnit,
    VariableRelationship,
} from "@/models";
import { H4ThinSpan, FormInput, FormInputSmall } from "@/components";
import { useModelVariableStore, useVariableValueForm } from "../../hooks/store";
import { IconButton, ModalContainer } from "../../modules/shared";
import { x } from "react-icons-kit/feather/x";
import { VariableRelationshipForm } from "./VariableRelationshipForm";
import { setVariableProperties } from "@/util";
import { VariableValueForm } from "./VariableValueForm";
import { SapienInertia, useModelBuilderPage } from "@/inertia-utils/hooks";

const getResampleFunctionKeys = (dataType: ModelVariableDataType) => {
    if (dataType === ModelVariableDataType.Number) {
        return ["sum", "mean", "last", "first", "mode", "min", "max"];
    } else if (dataType === ModelVariableDataType.Boolean) {
        return ["last", "first", "mode", "all", "any"];
    } else {
        return [];
    }
};

export const ModelVariableForm = ({}: {}) => {
    const { props: modelBuilderPageProps } = useModelBuilderPage();
    const { timeHorizons, type: modelBuilderPageSection } =
        modelBuilderPageProps;

    const { activeModelVariable, setActiveModelVariable } =
        useModelVariableStore();

    const [isBusy, setIsBusy] = useState(false);
    const [variableType, setVariableType] = useState<
        ModelVariableType | undefined
    >();
    const [canSubmit, setCanSubmit] = useState(false);
    const [areRelationshipsValid, setAreRelationshipsValid] = useState(true);
    const [variableRelationships, setVariableRelationships] =
        useState<VariableRelationship[]>();

    const [duplicateCount, setDuplicateCount] = useState(0);

    // handle setting variable value or map for independent variable type
    const {
        variableValue,
        setVariableValue,
        variableValueMap,
        setVariableValueMap,
        getBaseValuesToSave,
    } = useVariableValueForm(activeModelVariable, timeHorizons);

    // keep local variable type state in sync with active variable
    useEffect(() => {
        if (
            !!activeModelVariable &&
            variableType !== activeModelVariable.variable_type
        ) {
            setVariableType(activeModelVariable.variable_type);
            setDuplicateCount(0);
        }
    }, [activeModelVariable]);

    // update properties of new variables if type changes
    useEffect(() => {
        if (
            !!variableType &&
            !!activeModelVariable &&
            !activeModelVariable.id
        ) {
            setActiveModelVariable(
                setVariableProperties({
                    ...activeModelVariable,
                    variable_type: variableType,
                } as ModelVariable),
            );
        }
    }, [variableType]);

    // check validity of form data for submission
    useEffect(() => {
        if (
            !!activeModelVariable &&
            !!activeModelVariable.variable_type &&
            !!activeModelVariable.label
        ) {
            let canSubmitByVariableType = true;
            if (
                activeModelVariable.variable_type ===
                ModelVariableType.Aggregate
            ) {
                canSubmitByVariableType =
                    !!activeModelVariable.aggregate_operation;
            }
            if (
                (variableType === ModelVariableType.Independent ||
                    !!modelBuilderPageSection) &&
                activeModelVariable.data_type ===
                    ModelVariableDataType.Number &&
                !activeModelVariable.uses_time &&
                (activeModelVariable.minimum !== null ||
                    activeModelVariable.maximum !== null) &&
                !!variableValue
            ) {
                canSubmitByVariableType =
                    activeModelVariable.minimum !== null &&
                    activeModelVariable.maximum !== null &&
                    Number(activeModelVariable.minimum) <
                        Number(activeModelVariable.maximum) &&
                    Number(activeModelVariable.minimum) <=
                        Number(variableValue.numerical_value) &&
                    Number(variableValue.numerical_value) <=
                        Number(activeModelVariable.maximum) &&
                    (activeModelVariable.range_step == null ||
                        Number(activeModelVariable.range_step) <
                            Number(activeModelVariable.maximum) -
                                Number(activeModelVariable.minimum));
            }

            setCanSubmit(canSubmitByVariableType && areRelationshipsValid);
        } else {
            setCanSubmit(false);
        }
    }, [activeModelVariable, areRelationshipsValid, variableValue]);

    // helper function for setting properties of model variable
    const updateVariable = (prop: keyof ModelVariable, value: any) => {
        const updatedVariable = {
            ...activeModelVariable,
            ...{ [prop]: value },
        };
        setActiveModelVariable(updatedVariable as ModelVariable);
    };

    const renderAggregateOperationFormField = () => (
        <div className="mb-4 flex items-center">
            <div className="mr-4 text-sm font-semibold text-gray-900">
                Aggregate Operation
            </div>
            <div
                className="w-full items-stretch rounded border border-gray-200 bg-white text-xs font-medium
                    text-gray-900 sm:flex"
            >
                {Object.keys(ModelVariableAggregateOperation).map(
                    (key: any) => (
                        <div
                            key={key}
                            className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                                activeModelVariable.aggregate_operation ===
                                    ModelVariableAggregateOperation[key] &&
                                "bg-blue-50"
                            }`}
                        >
                            <div className="w-full px-0">
                                <label className="flex w-full items-center p-3 font-medium text-gray-900">
                                    <input
                                        type="radio"
                                        className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                            focus:ring-blue-500"
                                        checked={
                                            activeModelVariable.aggregate_operation ===
                                            ModelVariableAggregateOperation[key]
                                        }
                                        onChange={(e) =>
                                            updateVariable(
                                                "aggregate_operation",
                                                ModelVariableAggregateOperation[
                                                    key
                                                ],
                                            )
                                        }
                                        disabled={!!activeModelVariable.id}
                                    />
                                    {key}
                                </label>
                            </div>
                        </div>
                    ),
                )}
            </div>
        </div>
    );

    const renderUsesTimeFormField = () => (
        <div className="mb-4 flex items-center">
            <label className="flex items-center text-sm font-semibold text-gray-900">
                <input
                    type="checkbox"
                    className="mr-4 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                        focus:ring-blue-500"
                    checked={!!activeModelVariable.uses_time}
                    onChange={() =>
                        updateVariable(
                            "uses_time",
                            !activeModelVariable.uses_time,
                        )
                    }
                    disabled={
                        !!activeModelVariable.id ||
                        variableType === ModelVariableType.Iterative
                    }
                />
                {"Uses Time Horizons"}
            </label>
        </div>
    );

    const renderIsIntegerFormField = () => (
        <div className="mb-4 flex items-center">
            <label className="flex items-center text-sm font-semibold text-gray-900">
                <input
                    type="checkbox"
                    className="mr-4 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                        focus:ring-blue-500"
                    checked={!!activeModelVariable.is_integer}
                    onChange={() =>
                        updateVariable(
                            "is_integer",
                            !activeModelVariable.is_integer,
                        )
                    }
                />
                {"Integer Display"}
            </label>
        </div>
    );

    const renderIsAbsoluteValueFormField = () => (
        <div className="mb-4 flex items-center">
            <label className="flex items-center text-sm font-semibold text-gray-900">
                <input
                    type="checkbox"
                    className="mr-4 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                        focus:ring-blue-500"
                    checked={!!activeModelVariable.is_absolute_value}
                    onChange={() =>
                        updateVariable(
                            "is_absolute_value",
                            !activeModelVariable.is_absolute_value,
                        )
                    }
                />
                {"Absolute Value"}
            </label>
        </div>
    );

    const renderUnitFormField = () => (
        <div className="mb-4 flex items-center">
            <div className="mr-4 text-sm font-semibold text-gray-900">Unit</div>
            <div
                className="w-full items-center rounded border border-gray-200 bg-white text-xs font-medium
                    text-gray-900 sm:flex"
            >
                <div
                    className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                        !activeModelVariable.unit && "bg-blue-50"
                    }`}
                >
                    <div className="w-full px-0">
                        <label className="flex w-full items-center p-3 font-medium text-gray-900">
                            <input
                                type="radio"
                                className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                    focus:ring-blue-500"
                                checked={!activeModelVariable.unit}
                                onChange={(e) => updateVariable("unit", null)}
                            />
                            Default
                        </label>
                    </div>
                </div>
                {Object.keys(ModelVariableUnit)
                    .filter((key) => key !== "Custom")
                    .map((key: any) => (
                        <div
                            key={key}
                            className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                                activeModelVariable.unit ===
                                    ModelVariableUnit[key] && "bg-blue-50"
                            }`}
                        >
                            <div className="w-full px-0">
                                <label className="flex w-full items-center p-3 font-medium text-gray-900">
                                    <input
                                        type="radio"
                                        className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                            focus:ring-blue-500"
                                        checked={
                                            activeModelVariable.unit ===
                                            ModelVariableUnit[key]
                                        }
                                        onChange={(e) =>
                                            updateVariable(
                                                "unit",
                                                ModelVariableUnit[key],
                                            )
                                        }
                                    />
                                    {key == "Dollars" ? "Currency" : key}
                                </label>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );

    const renderResampleFunctionFormField = () => (
        <div className="mb-4 flex items-center opacity-50">
            <div
                className="mr-4 text-sm font-semibold text-gray-900"
                title="source default: MEAN — target default: SUM"
            >
                Resample Function
            </div>
            <div
                className="w-full items-center rounded border border-gray-200 bg-white text-xs font-medium
                    text-gray-900 sm:flex"
            >
                <div
                    className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                        !activeModelVariable.resample_function && "bg-blue-50"
                    }`}
                >
                    <div className="w-full px-0">
                        <label className="flex w-full items-center p-3 font-medium text-gray-900">
                            <input
                                type="radio"
                                className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                    focus:ring-blue-500"
                                checked={!activeModelVariable.resample_function}
                                disabled={
                                    activeModelVariable?.data_type ===
                                    ModelVariableDataType.Boolean
                                }
                                onChange={(e) =>
                                    updateVariable("resample_function", null)
                                }
                            />
                            none
                        </label>
                    </div>
                </div>
                {getResampleFunctionKeys(activeModelVariable?.data_type)?.map(
                    (key: any) => (
                        <div
                            key={key}
                            className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                                activeModelVariable.resample_function ===
                                    ModelVariableResampleFunction[key] &&
                                "bg-blue-50"
                            }`}
                        >
                            <div className="w-full px-0">
                                <label className="flex w-full items-center p-3 font-medium text-gray-900">
                                    <input
                                        type="radio"
                                        className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                            focus:ring-blue-500"
                                        checked={
                                            activeModelVariable.resample_function ===
                                            ModelVariableResampleFunction[key]
                                        }
                                        onChange={(e) =>
                                            updateVariable(
                                                "resample_function",
                                                ModelVariableResampleFunction[
                                                    key
                                                ],
                                            )
                                        }
                                    />
                                    {key}
                                </label>
                            </div>
                        </div>
                    ),
                )}
            </div>
        </div>
    );

    const renderDefaultNumericalValueFormField = () => (
        <div className="mb-4">
            <div className="mb-2 text-sm font-semibold text-gray-900">
                Default Numerical Value
            </div>
            <FormInput
                type="number"
                placeholder=""
                value={activeModelVariable.default_numerical_value}
                onChange={(e) => {
                    updateVariable(
                        "default_numerical_value",
                        Number(e.target.value),
                    );
                }}
                // disabled={!!activeModelVariable.id}
            />
        </div>
    );

    const renderDefaultBooleanValueFormField = () => (
        <div className="mb-4 flex items-center">
            <div className="mr-4 text-sm font-semibold text-gray-900">
                Default Boolean Value
            </div>
            <div
                className="w-full items-center rounded border border-gray-200 bg-white text-xs font-medium
                    text-gray-900 sm:flex"
            >
                <div
                    className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                        activeModelVariable.default_boolean_value === true &&
                        "bg-blue-50"
                    }`}
                >
                    <div className="w-full px-0">
                        <label className="flex w-full items-center p-3 font-medium text-gray-900">
                            <input
                                type="radio"
                                className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                    focus:ring-blue-500"
                                checked={
                                    activeModelVariable.default_boolean_value ===
                                    true
                                }
                                onChange={() => {
                                    if (
                                        !activeModelVariable.default_boolean_value
                                    )
                                        updateVariable(
                                            "default_boolean_value",
                                            true,
                                        );
                                }}
                                disabled={!!activeModelVariable.id}
                            />
                            true
                        </label>
                    </div>
                </div>
                <div
                    className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                        activeModelVariable.default_boolean_value === false &&
                        "bg-blue-50"
                    }`}
                >
                    <div className="w-full px-0">
                        <label className="flex w-full items-center p-3 font-medium text-gray-900">
                            <input
                                type="radio"
                                className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                    focus:ring-blue-500"
                                checked={
                                    activeModelVariable.default_boolean_value ===
                                    false
                                }
                                onChange={() => {
                                    if (
                                        activeModelVariable.default_boolean_value
                                    )
                                        updateVariable(
                                            "default_boolean_value",
                                            false,
                                        );
                                }}
                                disabled={!!activeModelVariable.id}
                            />
                            false
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderScopeFormField = () => (
        <div className="mb-4 flex items-center">
            <div className="mr-4 text-sm font-semibold text-gray-900">
                Scope
            </div>
            <div
                className="w-full items-center rounded border border-gray-200 bg-white text-xs font-medium
                    text-gray-900 sm:flex"
            >
                {Object.keys(ModelVariableScope)
                    .filter((key) => key !== "Simulation")
                    .map((key: any) => (
                        <div
                            key={key}
                            className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                                activeModelVariable.scope ===
                                    ModelVariableScope[key] && "bg-blue-50"
                            }`}
                        >
                            <div className="w-full px-0">
                                <label className="flex w-full items-center p-3 font-medium text-gray-900">
                                    <input
                                        type="radio"
                                        className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                            focus:ring-blue-500"
                                        checked={
                                            activeModelVariable.scope ===
                                            ModelVariableScope[key]
                                        }
                                        onChange={(e) =>
                                            updateVariable(
                                                "scope",
                                                ModelVariableScope[key],
                                            )
                                        }
                                        disabled={
                                            !!activeModelVariable.id &&
                                            !!variableRelationships?.length
                                        }
                                    />
                                    {key}
                                </label>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );

    const renderTypeFormField = () => (
        <div className="mb-4 flex items-center">
            <div className="mr-4 text-sm font-semibold text-gray-900">Type</div>
            <div
                className="w-full items-stretch rounded border border-gray-200 bg-white text-xs font-medium
                    text-gray-900 sm:flex"
            >
                {Object.keys(ModelVariableType)
                    .filter(
                        (key) =>
                            key !== "Selection Data" &&
                            ((!modelBuilderPageSection &&
                                key !== "Time Shift") ||
                                (!!modelBuilderPageSection &&
                                    key !== "Iterative")),
                    )
                    .map((key: any) => (
                        <div
                            key={key}
                            className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                                variableType === ModelVariableType[key] &&
                                "bg-blue-50"
                            }`}
                        >
                            <div className="w-full px-0">
                                <label className="flex w-full items-center p-3 font-medium text-gray-900">
                                    <input
                                        type="radio"
                                        className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                            focus:ring-blue-500"
                                        checked={
                                            variableType ===
                                            ModelVariableType[key]
                                        }
                                        onChange={(e) =>
                                            setVariableType(
                                                ModelVariableType[key],
                                            )
                                        }
                                        disabled={!!activeModelVariable.id}
                                    />
                                    {key}
                                </label>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );

    const renderExposeToDesignerFormField = () => (
        <div className="mb-4 flex items-center">
            <label className="flex items-center text-sm font-semibold text-gray-900">
                <input
                    type="checkbox"
                    className="mr-4 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                        focus:ring-blue-500"
                    checked={!!activeModelVariable.expose_to_designer}
                    onChange={() =>
                        updateVariable(
                            "expose_to_designer",
                            !activeModelVariable.expose_to_designer,
                        )
                    }
                />
                {"Expose to Designer"}
            </label>
        </div>
    );

    const renderExposeToFacilitatorFormField = () => (
        <div className="mb-4 flex items-center">
            <label className="flex items-center text-sm font-semibold text-gray-900">
                <input
                    type="checkbox"
                    className="mr-4 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                        focus:ring-blue-500"
                    checked={!!activeModelVariable.expose_to_facilitator}
                    onChange={() =>
                        updateVariable(
                            "expose_to_facilitator",
                            !activeModelVariable.expose_to_facilitator,
                        )
                    }
                />
                {"Expose to Facilitator"}
            </label>
        </div>
    );

    const renderIsKeyMetricFormField = () => (
        <div className="mb-4 flex items-center">
            <label className="flex items-center text-sm font-semibold text-gray-900">
                <input
                    type="checkbox"
                    className="mr-4 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                        focus:ring-blue-500"
                    checked={!!activeModelVariable.is_key_metric}
                    onChange={() =>
                        updateVariable(
                            "is_key_metric",
                            !activeModelVariable.is_key_metric,
                        )
                    }
                />
                {"Key Metric"}
            </label>
        </div>
    );

    return (
        <ModalContainer
            isModalOpen={!!activeModelVariable}
            setIsModalOpen={() => setActiveModelVariable(undefined)}
            size={"l"}
        >
            <div className="px-4">
                <div className="pb-8 pt-4">
                    <div className="flex flex-row items-center justify-between">
                        <H4ThinSpan>
                            {!!activeModelVariable?.id
                                ? `Edit Variable`
                                : `Create Variable`}
                        </H4ThinSpan>
                        <span>
                            <IconButton
                                icon={x}
                                handler={() => {
                                    setActiveModelVariable(undefined);
                                }}
                                isDisabled={false}
                            />
                        </span>
                    </div>
                </div>
                {!!activeModelVariable && (
                    <>
                        <div className="mb-4">
                            <div className="mb-2 text-sm font-semibold text-gray-900">
                                Label
                            </div>
                            <FormInput
                                type="text"
                                placeholder=""
                                value={activeModelVariable.label}
                                onChange={(e) => {
                                    updateVariable("label", e.target.value);
                                }}
                            />
                        </div>

                        {renderTypeFormField()}

                        {!!variableType &&
                            !modelBuilderPageSection &&
                            renderScopeFormField()}

                        {!!variableType &&
                            variableType === ModelVariableType.Aggregate &&
                            renderAggregateOperationFormField()}

                        {!!variableType &&
                            !modelBuilderPageSection &&
                            renderUsesTimeFormField()}

                        {!!variableType &&
                            activeModelVariable.data_type ===
                                ModelVariableDataType.Number &&
                            renderIsIntegerFormField()}

                        {!!variableType &&
                            activeModelVariable.data_type ===
                                ModelVariableDataType.Number &&
                            renderIsAbsoluteValueFormField()}

                        {!!variableType &&
                            activeModelVariable.data_type ===
                                ModelVariableDataType.Number &&
                            renderUnitFormField()}

                        {!!variableType &&
                            !!modelBuilderPageSection &&
                            renderResampleFunctionFormField()}

                        {!!variableType && renderExposeToDesignerFormField()}
                        {!!variableType && renderExposeToFacilitatorFormField()}
                        {!!variableType && renderIsKeyMetricFormField()}

                        {!!variableType &&
                            (variableType === ModelVariableType.Independent ||
                                !!modelBuilderPageSection) &&
                            activeModelVariable.data_type ===
                                ModelVariableDataType.Number &&
                            !activeModelVariable.uses_time && (
                                <>
                                    <div className="mb-4">
                                        <label className="mr-4 text-sm font-semibold text-gray-900">
                                            Min
                                        </label>
                                        <FormInputSmall
                                            type="number"
                                            placeholder=""
                                            value={
                                                activeModelVariable.minimum ??
                                                ""
                                            }
                                            name="minimum"
                                            onChange={(e) => {
                                                updateVariable(
                                                    "minimum",
                                                    e.target.value !== ""
                                                        ? Number(e.target.value)
                                                        : null,
                                                );
                                            }}
                                        />
                                        <label className="ml-6 mr-4 text-sm font-semibold text-gray-900">
                                            Max
                                        </label>
                                        <FormInputSmall
                                            type="number"
                                            placeholder=""
                                            value={
                                                activeModelVariable.maximum ??
                                                ""
                                            }
                                            name="maximum"
                                            onChange={(e) => {
                                                updateVariable(
                                                    "maximum",
                                                    e.target.value !== ""
                                                        ? Number(e.target.value)
                                                        : null,
                                                );
                                            }}
                                        />
                                        <label className="ml-6 mr-4 text-sm font-semibold text-gray-900">
                                            Step
                                        </label>
                                        <FormInputSmall
                                            type="number"
                                            placeholder=""
                                            min={0}
                                            value={
                                                activeModelVariable.range_step ??
                                                ""
                                            }
                                            name="range_step"
                                            onChange={(e) => {
                                                updateVariable(
                                                    "range_step",
                                                    e.target.value !== "" &&
                                                        Number(e.target.value) >
                                                            0
                                                        ? Number(e.target.value)
                                                        : null,
                                                );
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                        {!!variableType &&
                            variableType === ModelVariableType.Independent && (
                                <VariableValueForm
                                    variableValue={variableValue}
                                    setVariableValue={setVariableValue}
                                    variableValueMap={variableValueMap}
                                    setVariableValueMap={setVariableValueMap}
                                    timeHorizons={timeHorizons}
                                />
                            )}

                        {!!variableType &&
                            variableType !== ModelVariableType.Independent &&
                            activeModelVariable.data_type ===
                                ModelVariableDataType.Number &&
                            renderDefaultNumericalValueFormField()}

                        {!!variableType &&
                            activeModelVariable.data_type ===
                                ModelVariableDataType.Boolean &&
                            renderDefaultBooleanValueFormField()}

                        {!!activeModelVariable.id &&
                            activeModelVariable.variable_type !==
                                ModelVariableType.Independent &&
                            activeModelVariable.variable_type !==
                                ModelVariableType["Selection Data"] && (
                                <VariableRelationshipForm
                                    variableRelationships={
                                        variableRelationships
                                    }
                                    setVariableRelationships={
                                        setVariableRelationships
                                    }
                                    setAreRelationshipsValid={
                                        setAreRelationshipsValid
                                    }
                                />
                            )}
                        {!!variableType && !activeModelVariable?.id && (
                            <div className="mb-4 flex items-center">
                                <label className="mr-4 text-sm font-semibold text-gray-900">
                                    Duplicate Count
                                </label>
                                <FormInput
                                    type="number"
                                    placeholder=""
                                    value={duplicateCount}
                                    onChange={(e) => {
                                        setDuplicateCount(
                                            Math.max(
                                                0,
                                                Math.floor(
                                                    Number(e.target.value),
                                                ),
                                            ),
                                        );
                                    }}
                                />
                            </div>
                        )}

                        <div className="pb-2">
                            <button
                                type="submit"
                                className="mb-2 mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white
                                    hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300
                                    disabled:bg-blue-800"
                                disabled={isBusy || !canSubmit}
                                onClick={async (e) => {
                                    e.preventDefault();
                                    setIsBusy(true);

                                    let baseVariableValues =
                                        getBaseValuesToSave();

                                    let variableToSave = !!baseVariableValues
                                        ? {
                                              ...activeModelVariable,
                                              baseVariableValues,
                                          }
                                        : activeModelVariable;

                                    if (!!variableRelationships?.length) {
                                        variableToSave = {
                                            ...variableToSave,
                                            sourceVariableRelationships:
                                                variableRelationships,
                                        };
                                    }

                                    if (variableToSave?.id) {
                                        SapienInertia.put(
                                            "admin.model-variables.update",
                                            variableToSave,
                                            {
                                                modelVariableId:
                                                    variableToSave.id,
                                            },
                                            {
                                                preserveState: true,
                                                preserveScroll: true,
                                            },
                                        );
                                    } else {
                                        SapienInertia.post(
                                            "admin.model-variables.store",
                                            {
                                                ...variableToSave,
                                                duplicateCount,
                                            },
                                            {},
                                            {
                                                preserveState: true,
                                                preserveScroll: true,
                                            },
                                        );
                                    }

                                    setIsBusy(false);
                                    setActiveModelVariable(undefined);
                                }}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="mb-2 mr-2 rounded-lg border-2 border-red-600 px-5 py-2.5 text-sm font-medium
                                    text-red-600 hover:bg-red-800 hover:bg-opacity-5 focus:outline-none focus:ring-4
                                    focus:ring-red-300"
                                disabled={isBusy}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveModelVariable(undefined);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </>
                )}
            </div>
        </ModalContainer>
    );
};
