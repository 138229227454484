import React from "react";
import Icon from "react-icons-kit";
import { sliders } from "react-icons-kit/feather/sliders";
import { ModelVariable } from "@/models";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/solid";
import { DataCardValueMapItem } from "../types";

const InteractiveDashboardCardComponent = ({
    modelVariable,
    dataCardValueMapItem,
}: {
    modelVariable: ModelVariable;
    dataCardValueMapItem: DataCardValueMapItem;
}) => {
    const {
        variableValue,
        formattedValue,
        // archetypeValue,
        formattedArchetypeValue,
        difference,
        formattedDifference,
    } = dataCardValueMapItem;
    return (
        <div className="relative flex items-center justify-between p-5">
            {!!modelVariable.expose_to_designer ? (
                <span
                    title="Input Variable"
                    className="absolute right-2 top-2 text-slate-400 dark:text-slate-600"
                >
                    <Icon icon={sliders} style={{ display: "flex" }} />
                </span>
            ) : (
                <></>
            )}
            <div className="flex flex-col space-y-1">
                <div
                    className={`text-xl font-extrabold tracking-wide text-slate-700 dark:text-slate-300
                    ${!formattedValue ? "animate-pulse" : ""}`}
                    title={
                        !!variableValue
                            ? !!modelVariable.resample_function
                                ? `Year ${variableValue?.time_index + 1} Value (${modelVariable.resample_function})`
                                : `Year ${variableValue?.time_index + 1} Value`
                            : ""
                    }
                >
                    {formattedValue || "..."}
                </div>
                <div className="text-sm text-slate-600 dark:text-slate-400">
                    {modelVariable.label}
                </div>
            </div>
            <div
                className={`text-md ml-2 flex items-center font-bold ${
                    difference == 0 || !difference
                        ? "text-slate-500 dark:text-slate-500"
                        : difference > 0
                          ? "text-green-500"
                          : "text-red-500"
                } ${difference != undefined ? "" : "animate-pulse"}`}
                title={`Archetype Value: ${formattedArchetypeValue || "..."}`}
            >
                {`${formattedDifference}`}
                {difference == 0 || !difference ? (
                    ""
                ) : difference > 0 ? (
                    <ArrowUpIcon className="ml-0.5 h-4 w-4" />
                ) : (
                    <ArrowDownIcon className="ml-0.5 h-4 w-4" />
                )}
            </div>
        </div>
    );
};

export const InteractiveDashboardCard = React.memo(
    InteractiveDashboardCardComponent,
);
