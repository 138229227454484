import React from "react";
import { VariableTableRowProps } from "./props";
import { ModelVariableDataType } from "@/models";

const DefaultVariableDataTableRowComponent = ({
    modelVariable,
    variableValues,
    index,
    showResampleFunction,
    formatVariableValue,
}: VariableTableRowProps) => {
    return (
        <tr
            key={modelVariable.id}
            className={`table-row border-t border-slate-300/50 transition-all dark:border-slate-700/50
            ${index % 2 == 0 ? "bg-slate-200/50 dark:bg-slate-900/50" : ""} ${
                modelVariable.expose_to_designer
                    ? "text-slate-600 dark:text-slate-400"
                    : "text-slate-600 dark:text-slate-400"
            }`}
        >
            <th
                className={`table-col px-3 py-2 ${
                    showResampleFunction
                        ? "flex items-center justify-between"
                        : ""
                }`}
            >
                <span
                    className={`text-nowrap ${
                        modelVariable.expose_to_designer
                            ? "font-light text-slate-600 dark:text-slate-400"
                            : "font-semibold text-slate-700 dark:text-slate-300"
                    }`}
                >
                    {modelVariable.label}
                </span>
                {!!showResampleFunction && (
                    <span className="pl-2 text-xs font-thin text-slate-400 transition-all dark:text-slate-600">{`${modelVariable.resample_function}`}</span>
                )}
            </th>
            {variableValues?.map((value, index) => (
                <td
                    key={index}
                    className={`border-l border-slate-200 px-3 py-2 text-right transition-all
                        dark:border-slate-800`}
                    colSpan={1}
                >
                    {modelVariable.data_type === ModelVariableDataType.Number
                        ? formatVariableValue(
                              modelVariable.unit,
                              value.numerical_value,
                              modelVariable.is_integer,
                          )
                        : value.boolean_value.toString()}
                </td>
            ))}
        </tr>
    );
};

export const DefaultVariableDataTableRow = React.memo(
    DefaultVariableDataTableRowComponent,
);
