import React from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import { route } from "ziggy-js";
import { Cohort, Simulation, SimulationShape } from "@/models";

export const DiscoveryCohortList = ({
    simulation,
    cohorts,
}: {
    simulation: Simulation | SimulationShape;
    cohorts: Cohort[];
}) => {
    return (
        <div className="p-6">
            <div className="mb-5 text-2xl">Cohorts</div>
            <div className="grid w-full gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
                {cohorts
                    .filter((cohort) => cohort.title !== "Sandbox Cohort")
                    .reverse()
                    .map((cohort) => (
                        <div
                            key={cohort.id}
                            style={{ minHeight: "100%" }}
                            className="grid grid-cols-1 gap-2 rounded-md border bg-white p-4 hover:cursor-default
                                hover:shadow-md md:grid-cols-2"
                        >
                            <div className="flex flex-col justify-between space-y-1 pr-4">
                                <h2 className="text-md font-bold">
                                    {cohort.title}
                                </h2>
                                <div className="flex flex-col whitespace-nowrap text-xs tracking-tight text-[#6B7280]">
                                    <span>
                                        {`Created ${cohort.created_at?.substring(
                                            0,
                                            10,
                                        )}`}
                                    </span>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-2 md:grid-cols-4 md:gap-0">
                                <div className="flex flex-col justify-between">
                                    <div className="flex text-sm text-[#6B7280]">{`Teams`}</div>
                                    <div className="text-md font-semibold">{`${cohort.teams_count}`}</div>
                                </div>
                                <div className="flex flex-col justify-between">
                                    <div className="flex text-sm text-[#6B7280]">{`Participants`}</div>
                                    <div className="text-md font-semibold">{`${cohort.team_user_rounds_count}`}</div>
                                </div>
                                <div className="flex flex-col justify-between">
                                    <div className="flex text-sm text-[#6B7280]">{`Selections`}</div>
                                    <div className="text-md font-semibold">{`${cohort.selections_count}`}</div>
                                </div>
                                <div
                                    className="col-span-3 flex flex-row items-center space-x-1 md:col-span-1 md:flex-col
                                        md:space-x-0 md:space-y-1"
                                >
                                    <InertiaLink
                                        href={route(
                                            "admin.projects.discovery.show",
                                            {
                                                simulationSlug:
                                                    simulation?.slug,
                                                cohortId: cohort.id,
                                            },
                                        )}
                                    >
                                        <div
                                            className="w-full min-w-[100px] text-nowrap rounded-md border border-indigo-100
                                                bg-indigo-50 bg-opacity-50 px-2 py-1 text-center text-sm font-semibold
                                                text-indigo-800 hover:bg-indigo-100"
                                        >{`All Data`}</div>
                                    </InertiaLink>
                                    <InertiaLink
                                        href={route(
                                            "admin.projects.discovery.showStatistics",
                                            {
                                                simulationSlug:
                                                    simulation?.slug,
                                                cohortId: cohort.id,
                                            },
                                        )}
                                    >
                                        <div
                                            className="w-full min-w-[100px] text-nowrap rounded-md border border-indigo-100
                                                bg-indigo-50 bg-opacity-50 px-2 py-1 text-center text-sm font-semibold
                                                text-indigo-800 hover:bg-indigo-100"
                                        >{`Statistics`}</div>
                                    </InertiaLink>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};
