import { Dropdown } from "@/components";
import { sapienRoute } from "@/inertia-utils/hooks";
import { UserShape } from "@/models";
import { LaravelRoutes } from "@/ziggy-shims";
import React from "react";

type Props = {
    auth?: {
        user?: UserShape;
    };
    impersonatingUser?: UserShape;
    children?: JSX.Element;
    logoutRoute?: keyof LaravelRoutes;
};

export default function DropdownAvatar({
    auth,
    impersonatingUser,
    children,
    logoutRoute = 'logout'
}: Props) {
    return (
        <div className="hidden sm:flex sm:items-center cursor-pointer">
            <div className="relative">
                <Dropdown>
                    <Dropdown.Trigger>
                        {!!children ? (
                            children
                        ) : (
                            <span className="inline-flex rounded-md">
                                <button
                                    type="button"
                                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150 w-[max-content]"
                                >
                                    {!!impersonatingUser && (
                                        <span className="text-xs mr-1">
                                            {impersonatingUser?.name}{" "}
                                            impersonating
                                        </span>
                                    )}
                                    {auth.user?.name}

                                    <svg
                                        className="ml-2 -mr-0.5 h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </span>
                        )}
                    </Dropdown.Trigger>

                    <Dropdown.Content>
                        {!impersonatingUser ? (
                            <Dropdown.Link
                                href={sapienRoute(logoutRoute)}
                                method="post"
                                as="button"
                            >
                                Log Out
                            </Dropdown.Link>
                        ) : (
                            <Dropdown.Link
                                href={sapienRoute("leave-impersonation")}
                                method="post"
                                as="button"
                            >
                                Return to Admin
                            </Dropdown.Link>
                        )}
                    </Dropdown.Content>
                </Dropdown>
            </div>
        </div>
    );
}
