import React, { useEffect, useMemo, useState } from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import {
    Cohort,
    ModelBlock,
    ModelVariable,
    Round,
    SelectionShape,
    Simulation,
    VariableValue,
} from "@/models";
import {
    useSelectedSimulation,
    useInitAuthFromInertiaProps,
    useSelectedCohort,
    useAllCohorts,
    useTeamsOfSelectedCohort,
} from "@/hooks";
import { TopNavLinkDiv } from "@/components";
import { Head, Link as InertiaLink } from "@inertiajs/react";
import { route } from "ziggy-js";
import {
    DiscoveryCohortDetail,
    DiscoveryCohortList,
    DiscoveryMenu,
    DiscoveryMenuHeader,
} from "@/Layouts/discovery";
import { SapienInertia } from "@/inertia-utils/hooks";
import { useWebSockets } from "@/hooks/websockets/useWebsockets";
import ParticipantLinkModal from "./ParticipantLinkModal";
import { useNotificationStore } from "@/hooks/useNotificationStore";
import StyledToast from "@/components/toasts/StyledToast";
import LogoImage from "@/components/LogoImage";
import { useDiscoveryLayer } from "@/hooks/useDiscoveryLayer";
import { UserNavDropdown } from "@/Layouts/UserNavDropdown";
import Icon from "react-icons-kit";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { CohortQuestionStatistics } from "@/Layouts/statistics";

const toolbarHeight = 42;
const discoveryColors = {
    bgTop: "#15171a",
    bgMenu: "#2d2f31",
    bgPanel: "#1f1f1f",
    menuColor: "white",
};

type Props = SapienPageProps & {
    simulation: Simulation;
    cohort: Cohort | null;
    modelVariables: ModelVariable[] | null;
    variableValues: VariableValue[] | null;
    rounds: Round[];
    selections: SelectionShape[];
    modelBlocks: ModelBlock[];
};

export default function DiscoveryContainer(props: Props) {
    const {
        simulation,
        cohort,
        modelVariables,
        variableValues,
        rounds,
        selections,
        modelBlocks,
        auth,
        toasts,
        tenant,
    } = props;

    const { user } = useInitAuthFromInertiaProps(auth, null, null, tenant);
    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    const { selectedCohort, gotCohort } = useSelectedCohort();
    const { cohorts, gotCohorts } = useAllCohorts();
    const { teams } = useTeamsOfSelectedCohort();

    const discoveryLayerObject = useDiscoveryLayer(
        rounds,
        teams,
        modelVariables,
        variableValues,
        selections,
        modelBlocks,
    );

    const [isDropdownMenuOpen, setIsDropdownMenuOpen] =
        useState<boolean>(false);
    const [dropdownMenuSection, setDropdownMenuSection] =
        useState<string>("data");

    const [simpleStatisticsDisplay, setSimpleStatisticsDisplay] =
        useState<boolean>(true);

    useEffect(() => {
        if (
            !selectedSimulation ||
            selectedSimulation.id !== simulation.id ||
            !selectedSimulation.timeHorizons
        ) {
            gotSimulation(simulation);
        }
        gotCohorts(simulation.cohorts);
    }, [simulation]);

    function reloadOnNotify(_: any) {
        SapienInertia.reload({
            preserveScroll: true,
            preserveState: true,
        });
    }

    const { setUpFacilitatorSockets } = useWebSockets();
    useEffect(() => {
        gotCohort(cohort);

        if (cohort) {
            setUpFacilitatorSockets(cohort.id, {
                "facilitator.notify": reloadOnNotify,
            });
        }
    }, [cohort?.id]);

    const { notification, setNotification } = useNotificationStore();

    useEffect(() => {
        if (toasts && (toasts as any).length) {
            console.log("TOASTS FROM SERVER", toasts);
            setNotification({
                autoClose: toasts[0].autoClose || 3000,
                text: toasts[0].message,
                isInline: true,
                textColor: "#4B5563",
                backgroundColor: "#F0FFF4",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
            });
        }
    }, [toasts]);

    const title = useMemo(() => {
        if (selectedSimulation) return selectedSimulation.title;
        if (tenant) return tenant.name;
        return "Admin";
    }, [selectedSimulation, tenant]);

    const isStatisticsPage = useMemo(() => {
        return !modelVariables && !variableValues && !modelBlocks && !!cohort;
    }, [cohort, modelVariables, variableValues, modelBlocks]);

    return (
        selectedSimulation !== undefined &&
        simulation.id === selectedSimulation.id && (
            <>
                <Head title={title} />
                <div
                    className="discovery-container"
                    style={{
                        display: "flex",
                        height: "100vh",
                        flexDirection: "column",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <div
                        className="discovery-top"
                        style={{
                            display: "flex",
                            height: `${toolbarHeight}px`,
                            flexDirection: "row",
                        }}
                    >
                        <div
                            className="discovery-top-logo"
                            style={{
                                width: `${toolbarHeight}px`,
                                minWidth: `${toolbarHeight}px`,
                                height: `${toolbarHeight}px`,
                                background: discoveryColors.bgTop,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <TopNavLinkDiv style={{ padding: 0 }}>
                                <InertiaLink href={route("admin.index")}>
                                    <LogoImage size={"22"} opacity={"0.9"} />
                                </InertiaLink>
                            </TopNavLinkDiv>
                        </div>
                        <div
                            className="discovery-top-center"
                            style={{
                                width: `calc(100% - ${toolbarHeight}px)`,
                                minWidth: `calc(100% - ${toolbarHeight}px)`,
                                height: `${toolbarHeight}px`,
                                background: discoveryColors.bgTop,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                color: discoveryColors.menuColor,
                                transition: "all .25s ease",
                            }}
                        >
                            <div className="flex flex-row flex-wrap items-center">
                                <div className="hidden items-center sm:flex">
                                    <TopNavLinkDiv
                                        style={{
                                            padding: 0,
                                        }}
                                        className=""
                                    >
                                        <InertiaLink
                                            href={
                                                !!simulation &&
                                                route("admin.projects.show", {
                                                    sim: simulation.slug,
                                                })
                                            }
                                        >
                                            <div
                                                className="px-1 text-sm font-bold sm:px-3"
                                                style={{
                                                    color: discoveryColors.menuColor,
                                                }}
                                            >
                                                {`${simulation.title}`}
                                            </div>
                                        </InertiaLink>
                                    </TopNavLinkDiv>
                                    <div className="">
                                        <Icon
                                            icon={chevronRight}
                                            size={12}
                                            style={{
                                                display: "flex",
                                                color: discoveryColors.menuColor,
                                                opacity: 0.6,
                                            }}
                                        />
                                    </div>
                                </div>
                                {!cohort && (
                                    <div className="px-1 text-sm font-semibold sm:px-3">
                                        Discovery
                                    </div>
                                )}
                                {!!cohort && (
                                    <>
                                        <TopNavLinkDiv
                                            style={{
                                                padding: 0,
                                            }}
                                        >
                                            <InertiaLink
                                                href={route(
                                                    "admin.projects.discovery.index",
                                                    {
                                                        simulationSlug:
                                                            selectedSimulation?.slug,
                                                    },
                                                )}
                                            >
                                                <div
                                                    className="px-1 text-sm font-semibold sm:px-3"
                                                    style={{
                                                        color: discoveryColors.menuColor,
                                                    }}
                                                >
                                                    Discovery
                                                </div>
                                            </InertiaLink>
                                        </TopNavLinkDiv>
                                        <Icon
                                            icon={chevronRight}
                                            size={12}
                                            style={{
                                                display: "flex",
                                                color: discoveryColors.menuColor,
                                                opacity: 0.6,
                                            }}
                                        />
                                        <div className="px-1 text-sm font-normal sm:px-3">
                                            {`${cohort.title}`}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className="discovery-top-right-links"
                                style={{
                                    height: "100%",
                                    background: discoveryColors.bgTop,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: discoveryColors.menuColor,
                                    transition: "all .25s ease",
                                }}
                            >
                                {user && (
                                    <UserNavDropdown userName={user.name} />
                                )}
                            </div>
                        </div>
                    </div>

                    {!!cohort && !isStatisticsPage && (
                        <DiscoveryMenuHeader
                            isDropdownMenuOpen={isDropdownMenuOpen}
                            setIsDropdownMenuOpen={setIsDropdownMenuOpen}
                            dropdownMenuSection={dropdownMenuSection}
                            setDropdownMenuSection={setDropdownMenuSection}
                            toolbarHeight={toolbarHeight}
                        />
                    )}

                    {!!cohort && !!isDropdownMenuOpen && !isStatisticsPage && (
                        <div
                            className="discovery-dropdown-menu border-box absolute left-0 right-0 top-[82px] z-50
                                min-h-[42px] w-full border-y border-[#c3c3c3] bg-neutral-100 transition
                                duration-150 ease-in-out"
                            style={{
                                maxHeight: `calc(100vh - ${`${
                                    toolbarHeight * 2
                                }px`})`,
                                overflow: "auto",
                            }}
                        >
                            <DiscoveryMenu
                                cohort={cohort}
                                simulation={simulation}
                                dropdownMenuSection={dropdownMenuSection}
                                rounds={rounds}
                                modelBlocks={modelBlocks}
                                discoveryLayerObject={discoveryLayerObject}
                            />
                        </div>
                    )}

                    <div
                        className="discovery-bottom"
                        style={{
                            display: "flex",
                            height: `calc(100vh  - ${
                                (!!cohort && !isStatisticsPage ? 2 : 1) *
                                toolbarHeight
                            }px)`,
                            flexDirection: "row",
                        }}
                    >
                        <div
                            className="discovery-center-panel w-full"
                            style={{
                                transition: "all .25s ease",
                                height: "100%",
                                overflow: "auto",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    maxWidth: !cohort && "1290px",
                                    margin: "0 auto",
                                    minWidth: "300px",
                                }}
                            >
                                <div className="">
                                    {!cohort ? (
                                        <DiscoveryCohortList
                                            simulation={selectedSimulation}
                                            cohorts={cohorts}
                                        />
                                    ) : !isStatisticsPage ? (
                                        <DiscoveryCohortDetail
                                            rounds={rounds}
                                            modelBlocks={modelBlocks}
                                            simulation={selectedSimulation}
                                            cohort={selectedCohort}
                                            teams={teams}
                                            discoveryLayerObject={
                                                discoveryLayerObject
                                            }
                                        />
                                    ) : (
                                        <>
                                            <div className="mx-8 -mb-8 mt-4 flex items-center justify-end">
                                                <label
                                                    htmlFor={"stats-display"}
                                                >
                                                    <span className="flex items-center text-xs">
                                                        <input
                                                            id={"stats-display"}
                                                            type={"checkbox"}
                                                            checked={
                                                                simpleStatisticsDisplay
                                                            }
                                                            className={`text-primary-600 focus:ring-primary-500 mr-2 h-4 w-4 rounded border-gray-300
                                                                bg-gray-100 focus:ring-1`}
                                                            onChange={() => {
                                                                setSimpleStatisticsDisplay(
                                                                    !simpleStatisticsDisplay,
                                                                );
                                                            }}
                                                        />
                                                        {`Simple Display`}
                                                    </span>
                                                </label>
                                            </div>
                                            <CohortQuestionStatistics
                                                simulation={selectedSimulation}
                                                rounds={rounds}
                                                cohort={selectedCohort}
                                                teams={teams}
                                                selections={selections}
                                                simpleDisplay={
                                                    simpleStatisticsDisplay
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ParticipantLinkModal />
                {!!notification && (
                    <>
                        <StyledToast props={notification} />
                    </>
                )}
            </>
        )
    );
}
