import React, { useEffect, FC } from "react";
import { TopNavLinkDiv } from "@/components";
import {
    useGetContentBlocksByRoundId,
    useSetDerivedData,
    useSetActiveLineage,
    useEditorMenuSections,
    useSelectedSimulation,
    useSelectedRoundId,
    useVariableValueStore,
    useModalQueryParams,
} from "@/hooks";
import { useColorStore, useGotModelVariables } from "@/hooks/store";
import {
    RoundSelectionMenu,
    ContentBlockButtons,
    RoundListButton,
} from "./floating";
import { useRoundStore } from "@/hooks/store";
import { Link as InertiaLink } from "@inertiajs/react";
import { route } from "ziggy-js";
import EditorCenterColumn from "./EditorCenterColumn";
import StyledToast, { ToastProps } from "@/components/toasts/StyledToast";
import { useNotificationStore } from "@/hooks/useNotificationStore";
import { useDisplayRules } from "@/hooks/useDisplayRules";
import { EyeIcon } from "@heroicons/react/24/solid";
import { MobileViewButtons } from "./mobile-wrapper";
import { EditorContainerWrapper } from "./StyledEditorWrapper";
import { SapienInertia } from "@/inertia-utils/hooks";
import { LeftIconMenu } from "./LeftIconMenu";
import { RightIconMenu } from "./RightIconMenu";
import LogoImage from "@/components/LogoImage";
import EditorModals from "./modals/EditorModals";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import {
    useGetActiveContentBlock,
    useDiscardActiveContentBlock,
    useHasUnsavedChanges,
} from "@/hooks/activeContentBlock";
import EditorLeftMenu from "./EditorLeftMenu";
import EditorRightMenu from "./EditorRightMenu";
import { EditorContainerProps, editorColors } from "./EditorContainer";

export const DesignContainer: FC<EditorContainerProps> = (props) => {
    const {
        simulation,
        round,
        savedBlock,
        variableValues,
        currentPageId,
        contentBlocks,
        sapienErrors,
        toast,
    } = props;

    const { openModal } = useModalQueryParams();

    const { notification, setNotification } = useNotificationStore();
    useEffect(() => {
        if (toast) {
            setNotification({
                text: toast.message,
                backgroundColor: toast.type === "success" ? "green" : "red",
                autoClose: toast.autoClose || 5000,
            });
        }
    }, [toast]);

    const { selectedSimulation } = useSelectedSimulation();
    const { rounds: storeRounds } = useRoundStore();
    const { selectedRoundId } = useSelectedRoundId();

    const {
        leftMenuSection,
        rightMenuSection,
        setLeftMenuSection,
        setRightMenuSection,
        statefulEditorSizes,
        isLeftMenuOpen,
        toggleLeftMenu,
        toggleRightMenu,
        isRightMenuOpen,
    } = useEditorMenuSections();
    useEffect(() => {
        if (round && (!selectedRoundId || selectedRoundId !== round.id)) {
            if (!isLeftMenuOpen) {
                toggleLeftMenu();
            }
            if (isRightMenuOpen) {
                toggleRightMenu();
            }
        } else if (!round) {
            if (!isLeftMenuOpen) {
                toggleLeftMenu();
            }
            if (isRightMenuOpen) {
                toggleRightMenu();
            }
        }
    }, [savedBlock, selectedRoundId, round?.contentBlocks, round?.id]);

    const { setColorGroups } = useColorStore();
    useEffect(() => {
        setColorGroups(simulation?.colorGroups || []);
    }, [simulation]);

    const { gotContentBlocks } = useGetContentBlocksByRoundId();
    const { setDisplayRules } = useDisplayRules();
    const { gotVariableValues } = useVariableValueStore();
    useEffect(() => {
        if (contentBlocks) {
            gotContentBlocks(contentBlocks);
            setDisplayRules(contentBlocks);
            if (variableValues?.length) gotVariableValues(variableValues);
        }
    }, [selectedRoundId, contentBlocks, variableValues, currentPageId]);

    const { gotModelVariables } = useGotModelVariables();
    useEffect(() => {
        gotModelVariables(
            simulation?.modelBlocks?.reduce((carry, modelBlock) => {
                return [...carry, ...modelBlock.modelVariables];
            }, []) || [],
        );
    }, [simulation, simulation?.modelBlocks]);

    const hasUnsavedChanges = useHasUnsavedChanges();
    const { discardActiveContentBlock } = useDiscardActiveContentBlock();
    const {} = useSetDerivedData();
    const {} = useSetActiveLineage();
    const { data: activeContentBlock } = useGetActiveContentBlock();

    return (
        <>
            {!!notification && (
                <StyledToast props={notification as ToastProps} />
            )}
            <EditorContainerWrapper
                className="EDITOR-CONTAINER"
                style={{ position: "relative" }}
            >
                <div
                    className="EDITOR-TOP z-[10] flex flex-row"
                    style={{
                        height: statefulEditorSizes.toolbar,
                        background: editorColors.bgTop,
                    }}
                >
                    <div
                        className="EDITOR-LOGO flex justify-center"
                        style={{
                            width: statefulEditorSizes.toolbar,
                            minWidth: statefulEditorSizes.toolbar,
                            height: statefulEditorSizes.toolbar,
                            background: editorColors.bgTop,
                        }}
                    >
                        <TopNavLinkDiv style={{ padding: 0 }}>
                            <InertiaLink
                                href={
                                    !!selectedSimulation &&
                                    route("admin.projects.show", {
                                        sim: selectedSimulation.slug,
                                    })
                                }
                            >
                                <LogoImage size={"22"} opacity={"0.9"} />
                            </InertiaLink>
                        </TopNavLinkDiv>
                    </div>
                    <div
                        className="EDITOR-NAV-MENU z-[99] flex w-full flex-row justify-between text-white"
                        style={{ background: editorColors.bgTop }}
                    >
                        <div className="flex flex-row items-center">
                            <RoundListButton
                                isDisabled={hasUnsavedChanges}
                                selectedSimulation={selectedSimulation}
                                handleDiscard={discardActiveContentBlock}
                            />
                            <RoundSelectionMenu
                                isDisabled={hasUnsavedChanges}
                                selectedRoundId={selectedRoundId}
                                handleDiscard={discardActiveContentBlock}
                                rounds={storeRounds}
                            />
                            <button
                                onClick={() => {
                                    openModal({
                                        modalComponent:
                                            "DynamicContentHideRevealModal",
                                    });
                                }}
                                title={"Dynamic Content Display"}
                                className="relative hidden md:inline-flex items-center px-2 text-sm font-medium text-gray-400 hover:text-white"
                            >
                                <span className="sr-only">
                                    Open Dynamic Content Display Modal
                                </span>
                                <EyeIcon className="h-5 w-5" />
                            </button>
                        </div>
                        <div className="flex flex-row items-center">
                            <MobileViewButtons />
                            <ContentBlockButtons
                                showButtons={hasUnsavedChanges}
                                handleDiscard={discardActiveContentBlock}
                                handleSave={() => {
                                    SapienInertia.put(
                                        "admin.design.update",
                                        {
                                            ...activeContentBlock,
                                            theme: JSON.stringify(
                                                activeContentBlock.theme,
                                            ),
                                        } as unknown as (typeof LaravelRequestBodyShapes)["admin.design.update"],
                                        {
                                            simulationSlug:
                                                selectedSimulation.slug,
                                            roundId: selectedRoundId,
                                            contentBlock: activeContentBlock.id,
                                        },
                                        {
                                            preserveState: true,
                                            preserveScroll: true,
                                        },
                                    );
                                }}
                            />
                        </div>
                        <RightIconMenu
                            editorSizes={statefulEditorSizes}
                            rightMenuSection={rightMenuSection}
                            setRightMenuSection={setRightMenuSection}
                            isRightMenuOpen={isRightMenuOpen}
                            toggleRightMenu={toggleRightMenu}
                        />
                    </div>
                </div>
                <div
                    className="EDITOR-BOTTOM flex flex-row"
                    style={{
                        height: `calc(100vh - ${statefulEditorSizes.toolbar})`,
                    }}
                >
                    <>
                        <LeftIconMenu
                            editorSizes={statefulEditorSizes}
                            toggleLeftMenu={toggleLeftMenu}
                            isLeftMenuOpen={isLeftMenuOpen}
                            leftMenuSection={leftMenuSection}
                            setLeftMenuSection={setLeftMenuSection}
                        />
                        <EditorLeftMenu
                            leftMenuSection={leftMenuSection}
                            isOpen={isLeftMenuOpen}
                            currentPageId={currentPageId}
                            selectedRoundId={selectedRoundId}
                            width={270}
                        />
                    </>
                    <EditorCenterColumn
                        roundId={selectedRoundId}
                        editorSizes={statefulEditorSizes}
                        toggleLeftMenu={toggleLeftMenu}
                        sapienErrors={sapienErrors}
                    />
                    <EditorRightMenu
                        rightMenuSection={rightMenuSection}
                        isOpen={isRightMenuOpen}
                    />
                </div>
            </EditorContainerWrapper>
            <EditorModals currentPageId={currentPageId} />
        </>
    );
};
