import { ModelBlock, VariableValue } from "@/models";

export type InteractivePageSection =
    | "Dashboard"
    | "Settings"
    | "Data"
    | "Insights"
    | "Design"
    | "Guides";

export enum InputDataSettingType {
    timespan = "TIMESPAN",
    "linear interval" = "LINEAR_INTERVAL",
    "power interval" = "POWER_INTERVAL",
    "step interval" = "STEP_INTERVAL",
    constant = "CONSTANT",
    "boolean constant" = "BOOLEAN_CONSTANT",
    "boolean series" = "BOOLEAN_SERIES",
}

export type InputDataItem = {
    model_variable_id: string;
    label: string;
    numerical_value: number;
    initial: number;
    final: number;
    setting_type: InputDataSettingType;
    exponent: number;
    step_values: number[];
};

export enum InteractiveModuleChartType {
    LINE = "line",
    BAR = "bar",
    AREA = "area",
    PIE = "pie",
}

export type ChartBlock = {
    id: string;
    label: string;
    chart_type: InteractiveModuleChartType;
    time_increment: "month" | "year";
    time_index: number;
    stacked: boolean;
    interpolation: string;
    variableIds: string[];
    model_block_id: string;
    weight: number;
    created_at?: string;
    updated_at?: string;
    published_at?: string;
};

export type FormattedChartValue = {
    x: number;
    y: number;
    label: string;
    formattedValue: string;
};

export type InteractiveModuleArchetype = {
    id?: string;
    model_block_id: string;
    name: string;
    weight?: number;
    description?: string;
    is_default?: boolean;
    created_at?: string;
    updated_at?: string;
    published_at?: string;
    archetype: {
        inputDataObject: {
            [index: string]: InputDataItem;
        };
    };
};

export type InteractiveModelBlock = ModelBlock & {
    interactiveModuleArchetypes: InteractiveModuleArchetype[];
    interactiveModuleCharts: ChartBlock[];
};

export type LocalStorageInteractiveModelObject = {
    settings?: {};
    chartBlocks?: Record<string, ChartBlock>;
    variableWatchlist?: Record<string, boolean>;
    archetypeChartVariables?: Record<string, boolean>;
};

export type DataCardValueMapItem = {
    variableValue: VariableValue;
    formattedValue: string;
    archetypeValue: VariableValue;
    formattedArchetypeValue: string;
    difference: number;
    formattedDifference: string;
};
