import { useImageIsText } from "@/util/useImageIsText";
import React from "react";

type Props = {
    children: JSX.Element;
    imageUrl:string
};
export default function DashboardCard({children, imageUrl}:Props) {

    const imageIsText = useImageIsText({ imageUrl });

    return (
        <li
            className="relative list-none overflow-hidden rounded-lg border-gray-100 bg-white
                bg-center bg-no-repeat p-6 pt-48 shadow md:col-span-1 lg:pt-60"
            style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: imageIsText ? "contain" : "cover",
            }}
        >
            {children}
        </li>
    );
}
