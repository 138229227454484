import React, { useMemo } from "react";
import { TimeHorizon, RoundShape } from "@/models";
import { useForm } from "@inertiajs/react";
import { route } from "ziggy-js";
import { Switch } from "@headlessui/react";

interface RoundFormProps {
    round: RoundShape;
    cancel: () => void;
    timeHorizons: TimeHorizon[];
}

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export const RoundForm = ({ round, cancel, timeHorizons }: RoundFormProps) => {
    const { data, setData, post, put, processing } = useForm<
        Record<keyof RoundShape, any>
    >(round as Record<string, any>);

    const canSubmit = useMemo(() => {
        return (
            !!data &&
            !!data.simulation_id &&
            !!data.time_horizon_id &&
            !!data.title
        );
    }, [data]);

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    const intendedRoute = !!round.id
                        ? route("admin.rounds.update", { round: round.id })
                        : route("admin.rounds.store");

                    const method = !!round.id ? put : post;
                    method(intendedRoute, {
                        onSuccess() {
                            cancel();
                        },
                    });
                }}
            >
                <div className="flex flex-col space-y-4">
                    <div>
                        <label
                            htmlFor="title"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Title
                        </label>
                        <div className="mt-1 text-gray-700">
                            <input
                                onChange={(e) =>
                                    setData({ ...data, title: e.target.value })
                                }
                                value={data.title}
                                type="text"
                                name="title"
                                id="title"
                                className="block w-full rounded-md border-gray-300 text-gray-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="Round Title"
                            />
                        </div>
                    </div>

                    <div>
                        <label
                            htmlFor="time_horizon"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Time Horizon
                        </label>
                        <select
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    time_horizon_id: e.target.value,
                                })
                            }
                            value={data.time_horizon_id}
                            id="time_horizon"
                            name="time_horizon"
                            autoComplete="time_horizon"
                            className="relative mt-1 block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value={""} disabled>
                                Select Time Horizon
                            </option>
                            {timeHorizons.map((timeHorizon) => (
                                <option
                                    value={timeHorizon.id}
                                    key={timeHorizon.id}
                                >
                                    {timeHorizon.time_index}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4 space-y-2 border border-gray-200 p-4 sm:overflow-hidden sm:rounded-md">
                        <label className="block font-medium text-gray-700">
                            Configuration
                        </label>
                        <Switch.Group
                            as="div"
                            className="flex items-center justify-start space-x-2"
                        >
                            <span className="flex  flex-col">
                                <Switch.Label
                                    as="span"
                                    className="text-sm font-medium text-gray-900"
                                    passive
                                >
                                    Allow participants to advance
                                </Switch.Label>
                            </span>
                            <Switch
                                checked={data?.config?.allowSelfAdvance}
                                onChange={(allowSelfAdvance) =>
                                    setData({
                                        ...data,
                                        config: data.config
                                            ? {
                                                  ...data.config,
                                                  allowSelfAdvance,
                                              }
                                            : { allowSelfAdvance },
                                    })
                                }
                                className={classNames(
                                    data?.config?.allowSelfAdvance
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                                )}
                            >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        data?.config?.allowSelfAdvance
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                                    )}
                                />
                            </Switch>
                        </Switch.Group>
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="submit"
                            value="Submit"
                            className="mb-2 mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-blue-800"
                            disabled={processing || !canSubmit}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="mb-2 mr-2 rounded-lg border-2 border-red-600 px-5 py-2.5 text-sm font-medium text-red-600 hover:bg-red-800 hover:bg-opacity-5 focus:outline-none focus:ring-4 focus:ring-red-300"
                            disabled={processing}
                            onClick={(e) => {
                                e.preventDefault();
                                cancel();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
