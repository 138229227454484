import React, { useEffect, useMemo } from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { UserShape } from "@/models";
import { useForm } from "@inertiajs/react";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import { AdminContainer } from "@/Layouts/admin";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { SapienInertia } from "@/inertia-utils/hooks";
import { useNotificationStore } from "@/hooks/useNotificationStore";
import StyledToast from "@/components/toasts/StyledToast";
import { useInitAuthFromInertiaProps } from "@/hooks";

type Props = {
    roles: { id: number; name: string }[];
    users: UserShape[];
} & SapienPageProps;

export default function InviteUser({
    roles,
    users,
    tenant,
    progress_messages,
    errors,
    auth
}: Props) {


    useInitAuthFromInertiaProps(auth, null, null, tenant)
    const { setNotification, notification } = useNotificationStore();

    const usersByRole = useMemo((): { [index: string]: UserShape[] } => {
        return roles?.reduce((carry, role) => {
            const usersWithRole = users.filter(
                (user) =>
                    user.roles.some((userRole) => userRole.id === role.id) ||
                    (role.name === "Participant" && !user.roles?.length)
            );

            if (!usersWithRole?.length) return { ...carry };

            return {
                ...carry,
                [role.name]: usersWithRole,
            };
        }, {});
    }, [roles, users]);

    const { data, setData } = useForm({
        email: "",
        role_id: "-1",
        userRoleMap: {},
    });

    useEffect(() => {
        const userRoleMap = users
            .filter((user) => !!user?.roles?.length)
            .reduce((carry, user) => {
                return { ...carry, [user.id]: user.roles[0].id };
            }, {});

        setData("userRoleMap", userRoleMap);
    }, [usersByRole, users]);

    if (!usersByRole || !Object.keys(usersByRole).length) return <>?</>;

    return (
        <AdminContainer>
            {!!notification && <StyledToast props={notification} />}
            <AdminPageHeader pageTitle="Users"></AdminPageHeader>
            <AdminPageSection>
                <div>
                    <div className="text-center">
                        <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                        </svg>
                        <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">
                            Add team members
                        </h2>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            SapienInertia.post("admin.invitation.store", data);
                        }}
                        className="mt-6 flex"
                    >
                        <div className="w-full">
                            <div className="w-full flex items-end space-x-2">
                                <div className="w-full ">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Email & Role
                                    </label>
                                    <div className="mt-2 w-full flex flex-col space-y-2">
                                        <div className="relative rounded-md shadow-sm w-full">
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder={`user@simbuilder.io`}
                                                value={data.email}
                                                onChange={(e) => {
                                                    setData(
                                                        "email",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <div className="absolute inset-y-0 right-0 flex items-center border-l">
                                                <label
                                                    htmlFor="currency"
                                                    className="sr-only"
                                                >
                                                    Role
                                                </label>
                                                <select
                                                    id="currency"
                                                    name="currency"
                                                    className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                                    value={data.role_id}
                                                    onChange={(e) => {
                                                        setData(
                                                            "role_id",
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option>Role</option>
                                                    {roles.map((role) => (
                                                        <option
                                                            key={role.name}
                                                            value={role.id}
                                                        >
                                                            {role.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full max-w-xs flex">
                                    <button className="ml-4 flex items-center flex-shrink-0 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        <EnvelopeIcon className="w-6 h-6 mr-2" />
                                        <span>Send Invitation</span>
                                    </button>
                                </div>
                            </div>
                            <div>
                                {!!errors?.email && (
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-red-700"
                                    >
                                        {errors?.email}
                                    </label>
                                )}
                                {!!progress_messages &&
                                    progress_messages.map((message) => (
                                        <label
                                            key={message.message}
                                            htmlFor="email"
                                            className="block text-sm font-medium leading-6 text-green-700"
                                        >
                                            {message?.message}
                                        </label>
                                    ))}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="mt-10">
                    {Object.keys(usersByRole).map((role) => {
                        const people = usersByRole[role];
                        return (
                            <div className="flex flex-col divide-y space-y-2 mt-4">
                                <h3 className="text-sm font-medium text-gray-500">
                                    {role}s
                                </h3>
                                <ul
                                    role="list"
                                    className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200"
                                >
                                    {people.map((person, personIdx) => (
                                        <li
                                            key={personIdx}
                                            className="flex items-center justify-between space-x-3 py-4 "
                                        >
                                            <div className="flex min-w-0 w-full flex-1 justify-between items-center space-x-3">
                                                <p className="truncate text-sm font-medium text-gray-900">
                                                    {person.name}
                                                </p>
                                                <form>
                                                    <div className="flex items-center max-w-80">
                                                        <select
                                                            id="currency"
                                                            name="currency"
                                                            className="h-full rounded-md text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                                            value={
                                                                data
                                                                    ?.userRoleMap[
                                                                    person.id
                                                                ]
                                                            }
                                                            onChange={(e) => {
                                                                SapienInertia.post(
                                                                    "admin.user.update-role",
                                                                    {
                                                                        user_id:
                                                                            person.id,
                                                                        role_id:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    },
                                                                    {
                                                                        user: person.id,
                                                                    },
                                                                    {
                                                                        preserveScroll:
                                                                            true,
                                                                        preserveState:
                                                                            true,
                                                                        only: [
                                                                            "users",
                                                                            "toasts",
                                                                        ],
                                                                        onSuccess:
                                                                            (
                                                                                page
                                                                            ) => {
                                                                                console.log(
                                                                                    "success",
                                                                                    page
                                                                                );
                                                                                const {
                                                                                    toasts,
                                                                                } =
                                                                                    page?.props as SapienPageProps;

                                                                                if (
                                                                                    toasts?.length
                                                                                ) {
                                                                                    setNotification(
                                                                                        {
                                                                                            autoClose:
                                                                                                toasts[0]
                                                                                                    .autoClose ||
                                                                                                3000,
                                                                                            text: toasts[0]
                                                                                                .message,
                                                                                            textColor:
                                                                                                "#4B5563",
                                                                                            backgroundColor:
                                                                                                "#dcfbe4",
                                                                                            boxShadow:
                                                                                                "0px 1px 2px rgba(0, 0, 0, 0.08)",
                                                                                        }
                                                                                    );
                                                                                }
                                                                            },
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <option>
                                                                Role
                                                            </option>
                                                            {roles.map(
                                                                (role) => (
                                                                    <option
                                                                        key={`${person.id}_${role.name}`}
                                                                        value={
                                                                            role.id
                                                                        }
                                                                    >
                                                                        {
                                                                            role.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            </AdminPageSection>
        </AdminContainer>
    );
}
