import React, { useEffect } from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { Simulation, TimeHorizon } from "@/models";
import { AdminContainer, RoundList } from "@/Layouts/admin";
import { useSelectedSimulation, useInitAuthFromInertiaProps } from "@/hooks";
import { Icon } from "react-icons-kit";
import { layout } from "react-icons-kit/feather/layout";
import { pieChart } from "react-icons-kit/feather/pieChart";
import { box } from "react-icons-kit/feather/box";
import { clipboard } from "react-icons-kit/feather/clipboard";
import { sliders } from "react-icons-kit/feather/sliders";
import { compass } from "react-icons-kit/feather/compass";
import { ic_lightbulb_outline } from "react-icons-kit/md/ic_lightbulb_outline";
import { H4Span, Subtitle2Span } from "@/components";
import { AdminPageSection } from "@/components/admin-components";
import { Link as InertiaLink } from "@inertiajs/react";
import { route } from "ziggy-js";
import { useRoundStore, useTimeHorizonStore } from "@/hooks/store";

type Props = SapienPageProps & {
    simulation: Simulation & { is_course: boolean };
    timeHorizons: TimeHorizon[];
};

const iconSpanClassname = "mr-2 text-neutral-400";
const buttonLinkClassname = `m-1 inline-flex items-center text-neutral-${800} hover:bg-neutral-${700} hover:bg-opacity-5 focus:outline-none focus:ring-0 font-medium rounded-md text-sm px-3 py-2 text-center`;

export default function ProjectDetail({
    simulation,
    timeHorizons,
    auth,
}: Props) {
    const {} = useInitAuthFromInertiaProps(auth);
    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    const { gotTimeHorizons } = useTimeHorizonStore();
    const { gotRounds } = useRoundStore();

    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
        gotRounds(simulation.rounds || []);
    }, [simulation]);

    useEffect(() => {
        gotTimeHorizons(timeHorizons || []);
    }, [timeHorizons]);

    return (
        selectedSimulation !== undefined &&
        simulation.id === selectedSimulation.id && (
            <AdminContainer>
                <AdminPageSection backgroundColor="#F3F4F6">
                    <div className="flex flex-col">
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#6B7280",
                            }}
                        >
                            <Subtitle2Span>
                                {`${selectedSimulation.slug}`}
                            </Subtitle2Span>
                        </span>
                        <div>
                            <H4Span>{`${selectedSimulation.title}`}</H4Span>
                        </div>
                        <div className="-mb-3 flex flex-row flex-wrap justify-end">
                            <InertiaLink
                                href={route("interactive.index", {
                                    simulationSlug: simulation.slug,
                                })}
                            >
                                <button
                                    type="button"
                                    className={buttonLinkClassname}
                                    disabled={false}
                                >
                                    <span className={iconSpanClassname}>
                                        <Icon
                                            icon={compass}
                                            size={20}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </span>
                                    <span>Interactive</span>
                                </button>
                            </InertiaLink>
                            <InertiaLink
                                href={route("model-builder.index", {
                                    simulationSlug: simulation.slug,
                                })}
                            >
                                <button
                                    type="button"
                                    className={buttonLinkClassname}
                                    disabled={false}
                                >
                                    <span className={iconSpanClassname}>
                                        <Icon
                                            icon={box}
                                            size={20}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </span>
                                    <span>Builder</span>
                                </button>
                            </InertiaLink>
                            <InertiaLink
                                href={route("admin.projects.model-builder", {
                                    simulationSlug: simulation.slug,
                                })}
                            >
                                <button
                                    type="button"
                                    className={buttonLinkClassname}
                                    disabled={false}
                                >
                                    <span className={iconSpanClassname}>
                                        <Icon
                                            icon={pieChart}
                                            size={20}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </span>
                                    <span>Model</span>
                                </button>
                            </InertiaLink>
                            <InertiaLink
                                href={route("admin.projects.structure.index", {
                                    simulationSlug: simulation.slug,
                                })}
                            >
                                <button
                                    type="button"
                                    className={buttonLinkClassname}
                                    disabled={false}
                                >
                                    <span className={iconSpanClassname}>
                                        <Icon
                                            icon={sliders}
                                            size={20}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </span>
                                    <span>Structure</span>
                                </button>
                            </InertiaLink>
                            <InertiaLink
                                href={route("admin.projects.discovery.index", {
                                    simulationSlug: simulation.slug,
                                })}
                            >
                                <button
                                    type="button"
                                    className={buttonLinkClassname}
                                    disabled={false}
                                >
                                    <span className={iconSpanClassname}>
                                        <Icon
                                            icon={ic_lightbulb_outline}
                                            size={20}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </span>
                                    <span>Discovery</span>
                                </button>
                            </InertiaLink>
                            <InertiaLink
                                href={route("admin.projects.facilitate.index", {
                                    simulationSlug: simulation.slug,
                                })}
                            >
                                <button
                                    type="button"
                                    className={buttonLinkClassname}
                                    disabled={false}
                                >
                                    <span className={iconSpanClassname}>
                                        <Icon
                                            icon={clipboard}
                                            size={20}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </span>
                                    <span>Facilitation</span>
                                </button>
                            </InertiaLink>
                            <InertiaLink
                                href={route("admin.design.index", {
                                    simulationSlug: simulation.slug,
                                })}
                            >
                                <button
                                    type="button"
                                    className={buttonLinkClassname}
                                    disabled={false}
                                >
                                    <span className={iconSpanClassname}>
                                        <Icon
                                            icon={layout}
                                            size={20}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </span>
                                    <span>Design</span>
                                </button>
                            </InertiaLink>
                        </div>
                    </div>
                    <hr className="mt-3" />
                </AdminPageSection>
                <AdminPageSection>
                    <RoundList simulationId={selectedSimulation.id} />
                </AdminPageSection>
            </AdminContainer>
        )
    );
}
